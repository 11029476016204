import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import styles from "./ScoreMetric.module.scss"
import {
    FaRegArrowAltCircleUp,
    FaPlus,
    FaMinus,
    FaArrowCircleLeft,
    FaArrowRight,
    FaExclamationCircle
} from "react-icons/fa";
import {
    useHistory,
    useLocation,
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import ScoreBarGraph from '../ScoreBarGraph/ScoreBarGraph';
import ScorePie from '../ScorePie/ScorePie';
import GaugeChart from '../GaugeChart/GaugeChart';
import CreditPie from '../CreditPie/CreditPie';
import ScoreHalfDoughnut from '../ScoreHalfDoughnut/ScoreHalfDoughnut';
import Legend from "../../components/ScorecardLegend/ScoreCardLegend";
import Button from '../Button/Button';
import ScoreLicenses from '../ScoreLicenses/ScoreLicenses';
import ScoreCompBarGraph from '../ScoreCompBarGraph/ScoreCompBarGraph';


const ScoreMetric = ({ baseUrl, error, loading, isLoadingScores, scoresError, getScores, selectedData, setSelectedData, metrics, link, setLink, businessName, businessId, businessStatus, screenWidth, scoresData }) => {
    const [selectedMetric, setSelectedMetric] = useState("")
    const [metricData, setMetricData] = useState([]);
    const { path } = useRouteMatch();
    const { id } = useParams();
    const history = useHistory();


    useEffect(() => {
        if (!isLoadingScores) {
            const data = metrics.find(metric => metric.link === id);
            setSelectedData(data);
        }
    }, [isLoadingScores, id]);

    useEffect(() => {
        if (selectedMetric === "") return;
        const newMetricData = selectedData.metrics.find(metric => metric.index === selectedMetric);
        setMetricData(newMetricData);
    }, [selectedMetric, selectedData]);

    const getLink = (index, action) => {
        const id = action === "add" ? index + 1 : index - 1;
        const data = metrics.find(metric => metric.index === id);
        return data.link;
    }

    const openMore = (link) => {
        window.open(link, '_blank')
    }

    return (
        <div className={styles.scoreMetric}>
            <div className={styles.back}>
                <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    clicked={() => {
                        setLink("")
                        history.push('/dashboard/creditscore')
                    }}
                >
                    <FaArrowCircleLeft />
                </Button>
            </div>
            {!isLoadingScores && !scoresError && (
                <div className={styles.bizInfo}>
                    <div className={styles.biz}>
                        <h5>Business Name:</h5>
                        <h5 className={styles.bizAns}>
                            {businessName}
                        </h5>
                    </div>
                    <div className={styles.biz}>
                        <h5>Business Status:</h5>
                        <h5 className={styles.bizAns}>{businessStatus === 1 ? "Active" : "Inactive"}</h5>
                    </div>
                    <div className={styles.biz}>
                        <h5>Last Updated:</h5>
                        <h5 className={styles.bizAns}>
                            {new Date(scoresData[0]?.updatedAt * 1000).toISOString().split('T')[0]}
                        </h5>
                    </div>
                </div>
            )}
            <div className={styles.metricInfo}>
                <div className={styles.metricTop} style={{ padding: isLoadingScores && "80px 30px" }}>
                    {isLoadingScores && <p style={{ textAlign: "center", fontSize: "2rem", fontWeight: "bold", color: "#FFC83B", display: "flex", justifyContent: "center" }}><span style={{ marginRight: "5px" }}>Loading</span> {loading(60, 60, 9, "#FFC83B")}</p>}
                    {!isLoadingScores && (
                        <>
                            <div className={styles.nextIcons}>
                                <div className={styles.icon}><FaRegArrowAltCircleUp onClick={() => {
                                    setSelectedMetric("")
                                    setMetricData([])
                                    setLink(getLink(selectedData.index === 0 ? metrics.length : selectedData.index, "sub"))
                                }
                                } color='#ffffff' size={screenWidth < 767.98 ? "2.2rem" : "3rem"} style={{ transform: "rotate(270deg)" }} /></div>
                                <div className={styles.name}><h3>{selectedData.name}</h3></div>
                                <div className={styles.icon} style={{ display: "flex", justifyContent: "end" }}><FaRegArrowAltCircleUp onClick={() => {
                                    setSelectedMetric("")
                                    setMetricData([])
                                    setLink(getLink(selectedData.index === metrics.length - 1 ? -1 : selectedData.index, "add"))
                                }} color='#ffffff' size={screenWidth < 767.98 ? "2.2rem" : "3rem"} style={{ transform: "rotate(90deg)", }} /></div>
                            </div>
                            <h3 className={styles.score}>
                                Current Score:{isLoadingScores && " Loading..."} {!isLoadingScores && scoresError &&
                                    <span style={{}}><FaExclamationCircle size="1rem" style={{ marginRight: "5px" }} />An error occurred</span>
                                } {selectedData.score}
                            </h3>
                            <div className={styles.description}>
                                <p>{selectedData.icon}</p>
                                <p className={styles.desc}>{selectedData.desc}</p>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.metricBottom}></div>
            </div>
            <div className={styles.metrics}>
                {selectedData && selectedData.metrics ? (
                    selectedData.metrics.map((metric, index) => (
                        <div key={index}>
                            <div className={styles.metric} onClick={() => {
                                setSelectedMetric(selectedMetric === metric.index ? "" : metric.index);
                            }}>
                                <p>{metric.name}</p>
                                {metric.index === selectedMetric ? <FaMinus color='#000000' size={screenWidth < 767.98 ? "1rem" : "2rem"} /> : <FaPlus color='#000000' size={screenWidth < 767.98 ? "1rem" : "2rem"} />}
                            </div>

                            <div className={`${styles.metricDescription} ${metric.index === selectedMetric ? styles.show : styles.hide}`}>
                                {metricData && metricData.index === metric.index ? (
                                    <>
                                        <div className={styles.metricDesc}>
                                            <p>{metricData.desc}</p>
                                        </div>
                                        <div>{!isLoadingScores && !scoresError && metricData.chart === "bar" && (
                                            <div className={styles.bar}><ScoreBarGraph metricData={metricData} labels={metricData.labels} barData={metricData.data} /></div>
                                        )}</div>
                                        <div>{!isLoadingScores && !scoresError && metricData.chart === "compBar" && (
                                            <div className={styles.bar}><ScoreCompBarGraph labels={metricData.labels} barData1={metricData.data1} barData2={metricData.data2} /></div>
                                        )}</div>
                                        <div className={styles.metricChart}>
                                            {isLoadingScores && loading(60, 60, 9, "#C4233C")}
                                            {!isLoadingScores && scoresError && (
                                                <span style={{ fontSize: "1rem", display: "flex", alignItems: "center", color: "#C4233C" }}><FaExclamationCircle size="1rem" style={{ marginRight: "5px" }} />An error occurred</span>
                                            )}
                                            {!isLoadingScores && !scoresError && metricData.chart === "pie" && (
                                                <div className={styles.halfDoughnut}>
                                                    <div className={styles.legend}>
                                                        {metricData.legend.map((leg, index) => (
                                                            <Legend key={index} bgColor={leg.color} color="#000000" name={leg.title} />
                                                        ))}
                                                    </div>
                                                    <div className={styles.pie}>
                                                        <CreditPie metric={metricData} screenWidth={screenWidth} />
                                                    </div>
                                                </div>
                                            )}
                                            {!isLoadingScores && !scoresError && metricData.chart === "halfPie" && (
                                                <div className={styles.halfDoughnut}>
                                                    <div className={styles.legend}>
                                                        {metricData.legend.map((leg, index) => (
                                                            <Legend key={index} bgColor={leg.color} color="#000000" name={leg.title} />
                                                        ))}
                                                    </div>
                                                    <div className={styles.pie}>
                                                        <ScorePie averageScore={69.76} legend={false} />
                                                    </div>
                                                </div>
                                            )}
                                            {!isLoadingScores && !scoresError && metricData.chart === "halfdoughnut" && metricData.type !== "percentage" && (

                                                <div className={styles.halfDoughnut}>
                                                    <div className={styles.legend}>
                                                        {metricData.legend.map((leg, index) => (
                                                            <Legend key={index} bgColor={leg.color} color="#000000" name={leg.title} />
                                                        ))}
                                                    </div>
                                                    <div className={styles.pie}>
                                                        <ScoreHalfDoughnut metric={metricData} screenWidth={screenWidth} />
                                                    </div>
                                                </div>
                                            )}
                                            {!isLoadingScores && !scoresError && metricData.chart === "halfdoughnut" && metricData.type === "percentage" && (

                                                <div className={styles.halfDoughnut}>
                                                    <div className={styles.legend}>
                                                        {metricData.legend.map((leg, index) => (
                                                            <Legend key={index} bgColor={leg.color} color="#000000" name={leg.title} />
                                                        ))}
                                                    </div>
                                                    <div className={styles.pie}>
                                                        <ScorePie averageScore={metricData.value} legend={false} />
                                                    </div>
                                                </div>
                                            )}
                                            {!isLoadingScores && !scoresError && metricData.chart === "licenses" && (
                                                <ScoreLicenses
                                                    completed={metric.data ? (metric.data.filter((item) => item.status === 1).length / metric.data.length) * 100 : 0}
                                                    data={metric.data}
                                                    altData={metric.altData}
                                                    type={metric.type}
                                                    screenWidth={screenWidth} />
                                            )}
                                        </div>
                                        {!isLoadingScores && !scoresError && metricData.seeMore && (
                                            <div className={styles.metricLink}>
                                                <Button
                                                    clicked={() => openMore(metric.seeMore)}
                                                    size="sm"
                                                    bgColor="#CC6328"
                                                    color="#ffffff"
                                                    styles={styles.Button}
                                                >
                                                    View full report <FaArrowRight style={{ transform: 'rotate(-35deg)', marginBottom: '5px' }} />
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </div>

                        </div>
                    ))
                ) : (
                    <div></div>
                )}
            </div>
        </div >
    )
}

export default ScoreMetric