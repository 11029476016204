import React from 'react'
import styles from "./ScoreLicenses.module.scss"
import {
    FaCheckCircle, FaRegCircle, FaStar
} from "react-icons/fa";

const ScoreLicenses = ({ completed, data, altData, type, screenWidth }) => {
    const getStars = () => {
        return <FaStar color='#FFC83B' />
    }

    return (
        <div>
            {type === "permits" && (
                <div className={styles.percentage}>
                    <div className={styles.container}>
                        <div className={styles.cont} style={{ width: `${completed}%` }}>
                            {screenWidth >= 1360 && completed >= 15 && `${completed}% Completed`}
                            {screenWidth >= 992 && screenWidth < 1360 && completed >= 22 && `${completed}% Completed`}
                            {screenWidth >= 768 && screenWidth < 992 && completed >= 20 && `${completed}% Completed`}
                            {screenWidth >= 480 && screenWidth < 768 && completed >= 35 && `${completed}% Completed`}
                            {screenWidth < 480 && completed >= 50 && `${completed}% Completed`}
                        </div>
                    </div>
                    <div className={styles.completed}>
                        {screenWidth >= 1360 && completed < 15 && `${completed}% Completed`}
                        {screenWidth >= 992 && screenWidth < 1360 && completed < 22 && `${completed}% Completed`}
                        {screenWidth >= 768 && screenWidth < 992 && completed < 20 && `${completed}% Completed`}
                        {screenWidth >= 480 && screenWidth < 768 && completed < 35 && `${completed}% Completed`}
                        {screenWidth < 480 && completed < 50 && `${completed}% Completed`}
                    </div>
                </div>
            )}
            <div className={styles.tableCont}>
                <section className={styles.tableBody}>
                    <table className={styles.licenseTable} id="tableId" >
                        <thead>
                            <tr>
                                <th>{type === "permits" ? "Licenses/Permits" : "Coaching/Training"}</th>
                                <th>Status</th>
                                <th></th>
                                <th>{type === "permits" ? "Date Obtained" : "Rating"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 &&
                                data.map((license, index) => (
                                    <tr className={styles.tableRow} key={index}>
                                        <td>{license.name}</td>
                                        <td style={{
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                            color: license.status === 0 && "#C4233C"
                                        }}>
                                            {license.val !== 0 && type === "playbook" ? "Completed" :
                                                license.status === 1 && type === "permits" ? "Obtained" : "Pending"}
                                        </td>
                                        <td
                                        >
                                            <span>
                                                {license.val !== 0 && type === "playbook" ? <FaCheckCircle size="2rem" /> :
                                                    license.status === 1 && type === "permits" ? <FaCheckCircle size="2rem" /> : <FaRegCircle color='#C4233C' size="2rem" />}
                                            </span>
                                        </td>
                                        <td>
                                            {type === "playbook" ? (
                                                <>
                                                    {[...Array(5)].map((star, index) => {
                                                        const starValue = index + 1;
                                                        return (
                                                            <FaStar
                                                                key={index}
                                                                color={starValue <= license.val ? "#FFC83B" : '#e4e5e9'}
                                                            />
                                                        );
                                                    })}
                                                    <span style={{ marginLeft: "20px" }}>
                                                        {license.val === "" ? 0 : license.val} out of 5
                                                    </span>
                                                </>
                                            ) : (
                                                license.val
                                            )}
                                        </td>
                                    </tr>
                                ))
                            }
                            {!data &&
                                altData.map((license, index) => (
                                    <tr className={styles.tableRow} key={index}>
                                        <td>{license.name}</td>
                                        <td style={{ fontSize: "1rem", fontWeight: "bold", color: license.status === 0 && "#C4233C" }}>{license.status === 1 && type === "playbook" ? "Completed" : license.status === 1 && type === "permits" ? "Obtained" : "Pending"}</td>
                                        <td>
                                            <span>
                                                {license.status === 1 ? <FaCheckCircle size="2rem" /> : <FaRegCircle color='#C4233C' size="2rem" />}
                                            </span>
                                        </td>
                                        <td>
                                            {type === "playbook" ? (
                                                <>
                                                    {[...Array(5)].map((star, index) => {
                                                        const starValue = index + 1;
                                                        return (
                                                            <FaStar
                                                                key={index}
                                                                color={starValue <= license.val ? "#FFC83B" : '#e4e5e9'}
                                                            />
                                                        );
                                                    })}
                                                    <span style={{ marginLeft: "20px" }}>
                                                        {license.val === "" ? "0" : license.val} out of 5
                                                    </span>
                                                </>
                                            ) : (
                                                license.val
                                            )}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    )
}

export default ScoreLicenses