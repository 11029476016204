import React, { useState, useEffect, useContext } from "react";
import styles from "./LoanAmountForm.module.scss";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";
import { Row, Col } from "react-bootstrap";
import Slider from "react-rangeslider";
import { validateInput } from "../../utils/validateInput";
import { ToastContainer, toast } from "react-toastify";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import {
  convertInput,
  stripCommasInNumber,
} from "../../utils/convertInputType";
import "react-rangeslider/lib/index.css";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
import { Link, useHistory, useLocation } from "react-router-dom";


const LoanAmountForm = ({ delegateApply }) => {
  const history = useHistory();
  var _now_user = null;
  useEffect(() => {
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetLoanState(); 
    // });
    checkAndGetLoanState();

    function checkAndGetLoanState() {
      var y = new XMLHttpRequest();
      y.onload = function () {
        try {
          var a = JSON.parse(y.responseText);
          if (a.error) {
            console.log('load Error > :3', y.responseText);
            toast.error(a.message ? a.message : "Error");
          }
          _now_user = a.user;
          console.log('usr', a.user.user_id, a);
          if (a.user.user_id) {
            getCurrentLoanState(a.user.user_id);
          } else {
            // toast.error(a.message ? a.message : "User read error");
          }
        } catch (er) {
          console.log('load Error > :2', y.responseText, er);
        }
      };
      y.onerror = function () {
        console.log('load Error > :1', y.responseText);
      };
      y.open('GET', globalVars.baseUrl + globalVars.usrUnbox);
      y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      y.send();

      function getCurrentLoanState(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              // console.log('load Error > :3', y.responseText);
            }
            // console.log('usr', a);
            if (a.data.path) {
              if (window.location.pathname != a.data.path) {
                toast.info("Continue Loan application");
                history.push(a.data.path);
              } else {
                calculateRepaymentPeriod(a.data.loanInfo);
              }
            } else { }
          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
        };
        y.open('GET', globalVars.baseUrl + globalVars.getLoanState.replace(':user_id', usrId));
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    };

    function calculateRepaymentPeriod(info) {
      console.log('Loan info>', info, info.loanFundRepaymentPeriod);
      var repayOpts = '<option selected="true" disabled="1" value="0">--Select--</option>';
      for (var i = 0.25; i <= parseFloat(info.loanFundRepaymentPeriod); i += 0.25) {
        repayOpts += '<option value="' + i + '">' + (i < 1 ? (i * 12) + ' months' : i + ' year' + (i > 1 ? 's' : '')) + '</option>';
      }
      document.getElementById('loan-periods').innerHTML = repayOpts;
      document.getElementById('LoanAmountForm').style.display = 'block';
      document.getElementById('pre-loader').style.display = 'none';

      loanAmountPmtHandle(info);
    }


    function loanAmountPmtHandle(loan) {

      var frm = document.getElementById('loanAmountFrm');
      var loanFundBtn = document.getElementById('loan-fund-btn');
      var loadingOff = document.getElementById('loading-off');
      var loadingOn = document.getElementById('loading-on');

      loanFundBtn.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        submitLoanAmount();
      });
      frm.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        submitLoanAmount();
      });
      function submitLoanAmount() {
        // var loanFund = new_loan_fund[loanFundPartners.value];
        console.log('>>>', frm.amount.value);
        var payLoadData = {};
        if (!frm.amount.value) {
          toast.error("Enter Loan amount in digits without commas or special characters e.g 70000");
          return 0;
        }
        if (frm.amount.value != '') { frm.amount.value = frm.amount.value.replace(/\D/g, ''); }
        if (!frm.amount.value || !frm.years.value || frm.years.value == 0) {
          // loadingOff.style.display = 'initial';
          // loadingOn.style.display = 'none';
          toast.error("Enter Loan amount and select Repayment Period to continue");
          return 0;
        } else if (parseInt(frm.amount.value) > loan.loanFundMaxLoan) {
          toast.error("Maximum Loan available from your selected fund is " + loan.loanFundMaxLoan);
          return 0;
        } else if (!frm.name1.value) {
          toast.error("Your first name is required to proceed");
          return 0;
        } else if (!frm.name2.value) {
          toast.error("Your last name is required to proceed");
          return 0;
        } else if (!frm.idno.value) {
          toast.error("Your National ID number is required to proceed");
          return 0;
        } else if (!frm.contribution.value) {
          toast.error("Your Contribution Percentage is required to proceed");
          return 0;
        }
        loadingOff.style.display = 'none';
        loadingOn.style.display = 'initial';
        var formData = new FormData(frm);
        formData.append('payments_per_year', Math.floor(365 / parseInt(loan.loanFundRepaymentInterval)));
        formData.append('interest_rate', loan.loanFundInterest);
        formData.append('loan_id', loan._id);
        formData.append('proposal_link', loan.proposalLink);
        formData.append('loan_fund', loan.loanFundName);
        formData.append('loan_fund_id', loan.loanFundId);
        formData.append('currency', loan.loanFundCurrency);
        formData.append('user_name', _now_user.firstName + ' ' + _now_user.lastName);
        formData.append('phone', _now_user.phoneNumber);
        formData.append('email', _now_user.email);
        // console.log('_now_user', _now_user);

        // payLoadData.amount = parseInt(frm.amount.value);
        payLoadData.years = parseFloat(frm.years.value);

        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log('load Error > :3', y.responseText);
              toast.error(a.msg ? a.msg : "Error");
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
            } else {
              // console.log('>pmt>', a.data, a);
              payLoadData.amount = parseInt(a.data.in.amount);
              payLoadData.full_periods = a.data.out.full_periods;
              payLoadData.installment = a.data.out.installment;
              payLoadData.overflow_amount = a.data.out.overflow_amount;
              payLoadData.payable_interest = a.data.out.payable_interest;
              payLoadData.totalToPay = a.data.out.totalToPay;
              payLoadData.noOfInstallments = a.data.out.no_of_installments;
              payLoadData.interest_rate = a.data.in.interest_rate;
              payLoadData.contribution = a.data.in.contribution;
              payLoadData.amount_b4_contrib = a.data.out.amount_b4_contrib;
              submitLoanAmountDetails();
            }
          } catch (er) {
            console.log('load Error > :2', y.responseText, er);
            toast.error("Response error");
            loadingOff.style.display = 'initial';
            loadingOn.style.display = 'none';
          }
        };
        y.onerror = function () {
          console.log('load Error > :1', y.responseText);
          var a = JSON.parse(y.responseText);
          toast.error(a.message ? a.message : "Failed to get user details");
          loadingOff.style.display = 'initial';
          loadingOn.style.display = 'none';
        };
        // y.open('POST', globalVars.pmtCalcUrl);
        y.open('POST', globalVars.pmtCalcUrl2Score);
        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send(formData);

        function submitLoanAmountDetails() {
          var usrId = loan.userId;
          var y = new XMLHttpRequest();
          y.onload = function () {
            try {
              var a = JSON.parse(y.responseText);
              if (a.error) {
                console.log('load Error > :3', y.responseText);
                handelPropResp(false, y.responseText);
              } else {
                handelPropResp(true, a.data);
              }
            } catch (er) {
              console.log('load Error > :2', y.responseText, er);
              handelPropResp(false, y.responseText);
            }
          };
          y.onerror = function () {
            console.log('load Error > :1', y.responseText);
            handelPropResp(false, y.responseText);
          };
          y.open('POST', globalVars.baseUrl + globalVars.addLoanAmount.replace(':user_id', usrId));
          y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
          y.setRequestHeader("Content-Type", "application/json");
          y.send(JSON.stringify(payLoadData));

          function handelPropResp(status, resp) {
            if (!status) {
              console.log('Error>>');
              loadingOff.style.display = 'initial';
              loadingOn.style.display = 'none';
              toast.error(resp.error ? resp.error : "Failed to add loan, Try again");
              return 0;
            } else {
              console.log(resp);
              toast.success(resp.message ? resp.message : "Details updated");
              history.push("/dashboard/consumer-credit/success");
            }

          }
        }
      }


    }
  });

  const fundSelectStyle = {
    display: "block",
    width: "100%",
    padding: "10px 7px",
    margin: "5px 0px",
    border: "1px solid #909090",
    borderRadius: "4px",
    background: "#f0f0f0",
    color: "#212121",
    fontSize: "1em"
  }
  const loanFundBtn = {
    width: "100%",
    backgroundColor: "rgb(204, 99, 40)",
    padding: "12px 28px",
    color: "rgb(235, 235, 235)",
    border: "none",
    borderRadius: "4px",
    font: "700 1.2em quicksand, sans-serif"
  };

  return (
    <div>
      <div className={styles.amountForm} id="LoanAmountForm">
        <ToastContainer position="top-center" />
        <form id="loanAmountFrm">
          <Row className="mb-4">
            <Col className="mb-4 mb-md-0" sm={12} md={12}>
              <h2>Loan Amount & Additional Details</h2>
              <p style={{ fontSize: "1em", fontWeight: "500" }}>If you're applying for the first time, you won't receive more than Ksh 100,000.00</p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Loan Amount</label>
                <input id="loan-amount" name="amount" className={styles.infoInp} type="number" placeholder="70000" />
              </div>
            </Col>
            <Col>
              <div>
                <label class="fund-label">Select Repayment Period</label>
                <select id="loan-periods" name="years" style={fundSelectStyle}>
                  <option selected="true" disabled="1" value="0">--Select--</option>
                </select>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <p style={{ paddingLeft: "15px" }}>Enter first name and last name as it appears in your national ID</p>
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>First Name</label>
                <input id="loan-name1" name="name1" className={styles.infoInp} type="text" />
              </div>
            </Col>
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>Last Name</label>
                <input id="loan-name2" name="name2" className={styles.infoInp} type="text" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4 mb-md-0" sm={12} md={6}>
              <div className={styles.infoDiv}>
                <label className={styles.infoLbl}>National ID number</label>
                <input id="loan-idno" name="idno" className={styles.infoInp} type="text" />
              </div>
            </Col>
            <Col>
              <div>
                <label class="fund-label">Contribution Percentage</label>
                <select id="loan-contribution" name="contribution" style={fundSelectStyle}>
                  <option selected="true" disabled="1" value="0">--Select--</option>
                  <option value="10">10%</option>
                  <option value="20">20%</option>
                  <option value="30">30%</option>
                  <option value="50">50%</option>
                </select>
              </div>
            </Col>
            <p style={{ paddingLeft: "15px" }}><i>Note* The amount of loan you receive will be deducted from amount you apply based on contribution percentage</i></p>
          </Row>
          <Row>
            <Col>
              <button class="mt-5" id="loan-fund-btn" style={loanFundBtn}>
                <span id="loading-off">Complete</span>
                <span id="loading-on" style={{ display: "none" }}><Loader /></span>
              </button>
            </Col>
          </Row>
        </form>

      </div>
      <div id="pre-loader" className={styles.pre_ld}> <Loader /> </div>
    </div>
  );
};

export default LoanAmountForm;
