import React, { useState, useContext, useEffect } from "react";
import styles from "./QSurvey.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Table } from "react-bootstrap";
import noLoan from "../../assets/no-loan.png";
import welcomeToAcc from "../../assets/welcomeBuruka.png";
import somoLogo from "../../assets/logo12.png";
import somoLogo2 from "../../assets/logo.png";
import { Row, Col, Modal } from "react-bootstrap";
import {
  Fa500Px,
  FaAccusoft,
  FaAmilia,
  FaArrowCircleLeft,
  FaArrowRight,
  FaBatteryEmpty,
  FaBatteryQuarter,
  FaBell,
  FaBellSlash,
  FaBomb,
  FaBook,
  FaBookOpen,
  FaBookReader,
  FaBusinessTime,
  FaCalendarCheck,
  FaCarBattery,
  FaChalkboardTeacher,
  FaCheck,
  FaCheckCircle,
  FaCheckDouble,
  FaCheckSquare,
  FaCircle,
  FaCircleNotch,
  FaClock,
  FaCloudUploadAlt,
  FaFileUpload,
  FaFlagCheckered,
  FaHome,
  FaMailBulk,
  FaMoneyCheckAlt,
  FaRegBell,
  FaRocket,
  FaSms,
  FaSquare,
  FaSquareFull,
  FaSquareRootAlt,
  FaStopwatch,
  FaTimes,
  FaTimesCircle,
  FaUserShield,
  FaWalking,
  FaWineGlassAlt,
} from "react-icons/fa";
import { TiCancelOutline } from "react-icons/ti";
import resolveToken from "../../utils/resolveToken";
import globalVars from "../../utils/globalVars";
import Loader from "react-spinners/BeatLoader";
// import Loader from "react-spinners/BounceLoader";
// import Loader1 from "react-spinners/ScaleLoader";
// import Loader2 from "react-spinners/ClimbingBoxLoader";
// import Loader3 from "react-spinners/ClipLoader";
import LoaderPre from "react-spinners/ClockLoader";
// import Loader5 from "react-spinners/RingLoader";
// import Loader6 from "react-spinners/PuffLoader";
// import Loader7 from "react-spinners/RotateLoader";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
// import Loader10 from "react-spinners/PacmanLoader";
// import Loader11 from "react-spinners/PropagateLoader";
// import Loader12 from "react-spinners/PuffLoader";
// import Loader13 from "react-spinners/PulseLoader";
import ApplicantUniUploadForm from "../../components/ApplicantUniUploadForm/ApplicantUniUploadForm";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Context as LoanContext } from "../../context/LoanContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { applicRoutes } from "../../routes/sidebarRoutes";
import _ from "lodash";
import { renderToStaticMarkup } from "react-dom/server";
import { FadeLoader } from "react-spinners";
import {
  FaBoxArchive,
  FaClockRotateLeft,
  FaDiagramNext,
  FaTemperatureQuarter,
  FaTimeline,
  FaUpload,
} from "react-icons/fa6";

const QSurvey = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const applyStageArray = {
    "/dashboard/applicant/apply/activate-done-at-login": 0,
    "/dashboard/applicant/apply/start": 1,
    "/dashboard/applicant/apply/id_front": 2,
    "/dashboard/applicant/apply/id_back": 3,
    "/dashboard/applicant/apply/kra_pin": 4,
    "/dashboard/applicant/apply/b_name": 5,
    "/dashboard/applicant/apply/b_bank": 6,
    "/dashboard/applicant/apply/invest_overview": 7,
    "/dashboard/applicant/apply/invest_video": 8,
    "/dashboard/applicant/apply/invest_image": 9,
    "/dashboard/applicant/apply/invest_logo": 10,
    "/dashboard/applicant/apply/e_map": 11,
    "/dashboard/applicant/apply/conduct": 12,
    "/dashboard/applicant/apply/photography": 13,
    "/dashboard/applicant/apply/final": 14,
    // "/dashboard/consumer-credit/apply/calculator": 0,
    // "/dashboard/consumer-credit/apply/fund": 1,
    // "/dashboard/consumer-credit/apply/amount": 2,
    // "/dashboard/consumer-credit/apply/bank-info": 3,
  };

  const getUserType = (path) => {
    if (path.includes("/applicant/")) {
      return "applicant";
    } else if (path.includes("/client/")) {
      return "client";
    } else {
      return "unknown";
    }
  };

  const initialPath = getUserType(location.pathname);
  const applicantBasePath = "applicant";
  const [applicantPath, setApplicantPath] = useState(
    "/dashboard/qsurvey/applicant/"
  );
  const [clientPath, setClientPath] = useState("/dashboard/qsurvey/client/");

  const {
    state: { loans, error, currentLoanId, incomplete, loanApplicationStage },
    retrieveClientLoans,
    clearError,
    loanApply,
    addAddressForLoan,
    addWorkInfoForLoan,
    addBankInfoForLoan,
    clearCompleteState,
  } = useContext(LoanContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    // console.log('psr>>', user);
    // window.addEventListener('DOMContentLoaded', function(){
    //   checkAndGetOnboardingState();
    // });
    var tpl = {
      advisorsList: `<div class="${styles.advisorList
        } advisor-list-each" pairId="{pairId}">
        ${renderToStaticMarkup(
          <FaUserShield size="1.2em" color="#cc6328" class={styles.icon} />
        )}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>${renderToStaticMarkup(
          <FaBookReader size="21px" color="#cc6328" class={styles.icon} />
        )} - {playbook}</span>
            <span>{email}</span>
            <span>{phone}</span>
          </div>
          <input type="checkbox" />
          {checkIcon}
        </div>`,
      iconChecked: renderToStaticMarkup(
        <FaCheckCircle class={styles.icon + " " + styles.iconChecked} />
      ),
      iconNonChecked: renderToStaticMarkup(
        <FaCircleNotch class={`${styles.icon} iconNonChecked`} />
      ),
      iconCheckedHover: renderToStaticMarkup(
        <FaCheckCircle
          class={`${styles.icon} ${styles.iconChecked} ${styles.iconCheckedHover}`}
        />
      ),
      loader: renderToStaticMarkup(<LoaderIntro color="#00a5a2" />),
      playbooksList: `<div class="${styles.advisorList
        } advisor-list-each" playbookId="{playbookId}">
        ${renderToStaticMarkup(
          <FaBookReader size="1.2em" color="#cc6328" class={styles.icon} />
        )}
          <div class="${styles.advisorListInfo}">
            <span>{playbook}</span>
            <span>{progress}</span>
            <span>{status}</span>
            <span>${renderToStaticMarkup(
          <FaUserShield size="21px" color="#cc6328" class={styles.icon} />
        )} - {email}</span>
          </div>
        </div>`,
      playbookChapterList: `<div class="${styles.advisorList
        } advisor-list-each" chapterId="{chapterId}">
        ${renderToStaticMarkup(
          <FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />
        )}
          <div class="${styles.advisorListInfo}">
            <span>{name}</span>
            <span>No. {pos}</span>
          </div>
        </div>`,
      playbookSessionList: `<div class="${styles.advisorList
        } advisor-list-each" sessionId="{sessionId}">
      ${renderToStaticMarkup(
          <FaBookOpen size="1.2em" color="#cc6328" class={styles.icon} />
        )}
        <div class="${styles.advisorListInfo}">
          <span>{name}</span>
          <span>No. {pos}</span>
        </div>
      </div>`,
      loaderMain: `<span style=${{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100px",
      }}>Loading...&nbsp;${renderToStaticMarkup(
        <LoaderIntro color="#00a5a2" />
      )}</span>`,
      sesTtlTtl: `<h3>{pos}.</h3><h3>{ttl}</h3>`,
      sesToolList: `<div class="${styles.toolCheck}">
        <img src="https://invest.somoafrica.org/img/playbook/{img}" />
        <div class="${styles.toolTtl}">
          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">{info}</p>
          <a class="subPlaybookListLinks" href="{value}" target="blank">{name}</a>
        </div>
        {statusIcon}
      </div>`,
      sesToolListDone: `<div class="${styles.checkStatus}">
          <p>Completed</p>
          ${renderToStaticMarkup(
        <FaCheckCircle size="4em" class={styles.icon} />
      )}
        </div>`,
      sesToolListWait: `<div class="${styles.checkStatus}">
        <p>Pending...</p>
        ${renderToStaticMarkup(<FadeLoader size="4em" class={styles.icon} />)}
      </div>`,
      sesTaskList: `<div class="${styles.task}">
        <div class="${styles.top}">
          <p><b>Topic : </b><span>{topic}</span></p>
          <p><b>Due Date : </b><span>{duedate}</span></p>
          <p><b>Response Date : </b><span>{resdate}</span></p>
        </div>
        <textarea readonly="">{task}</textarea>
        <div class="${styles.bottom}">
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
          <button id="{id}" class="start-task-reply-btn {hide}">Reply</button>
        </div>
      </div>`,
      sesListFiles: `<a href="{link}" target="_blank">File {count}</a>`,
      sesFeedList: `<div class="${styles.feed}">
      ${renderToStaticMarkup(
        <FaUserShield size="5em" color="#cc6328" className={styles.icon} />
      )}
        <div class="${styles.left}">
          <p>By : {advisor}</p>
          <div class="${styles.msg}">{feed}</div>
          <p class="${styles.file}">
            <b>Files : </b>
            {files}
          </p>
        </div>

      </div>`,
      quizList: `<div class="${styles.quizList} {hide} {opt}" id="{id}">
        <div class="${styles.quizListInfo}">
          <label>
            <p>{quiz}</p>
            <textarea name={name} code="{code}" rows="1" required="1">{val}</textarea>
          </label>
        </div>
      </div>`,
      quizListOpt: `<div class="${styles.quizList} {hide} {opt}" id="{id}">
        <div class="${styles.quizListInfo}">
          <label>
            <p>{quiz}</p>
            <select name={name} code="{code}" required="1">{val}</select>
          </label>
        </div>
      </div>`
    };

    if (document.getElementById("usr_name_salt") && user.firstName) {
      document.getElementById("usr_name_salt").innerText = user.firstName;
    }

    if (document.getElementById("survey_language")) {
      // document.getElementById('usr_name_salt').innerText = user.firstName;
      var qSurvey = localStorage.getItem("qSurvey")
        ? JSON.parse(localStorage.getItem("qSurvey"))
        : null;
      if (!qSurvey) {
        qSurvey = {
          lang: "en",
        };
        localStorage.setItem("qSurvey", JSON.stringify(qSurvey));
      }
      document.getElementById("survey_language").value = qSurvey.lang;

      document
        .getElementById("survey_language")
        .addEventListener("change", function () {
          qSurvey.lang = this.value;
          localStorage.setItem("qSurvey", JSON.stringify(qSurvey));
        });
    }

    if (document.getElementById("quizList")) {
      var quizList = document.getElementById("quizList");
      quizList.innerHTML = tpl.loaderMain;

      var surveyLang = "en";
      var dependents = {};
      var frm = document.forms.q_survey_form;
      var submitBtn = document.getElementById("surveySubmitBtn");
      var submitLoader = document.getElementById("surveySubmitLoader");
      var qSurvey = localStorage.getItem("qSurvey")
        ? JSON.parse(localStorage.getItem("qSurvey"))
        : null;
      var surveyCreateTime = parseInt(Date.now() / 1000);
      if (qSurvey) {
        if (qSurvey.lang) surveyLang = qSurvey.lang;
      }
      if (quizList.attributes.survey_info.value == "quarterly_survey") {
        var menuTxt = "2*14*1";
      } else if (
        quizList.attributes.survey_info.value == "post_quarterly_survey"
      ) {
        var menuTxt = "2*14*2";
      } else {
        console.log("load Error > :3.-1", "Unmapped survey");
        toast.error("Error Occured " + "Unmapped survey");
        quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  Unmapped survey</div>`;
        return 0;
      }

      frm.addEventListener("submit", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        submitLoader.style.display = "flex";
        quizList.style.display = "none";
        submitBtn.style.display = "none";
        var jsonPayload = {
          sessionPhoneNumber: user.phoneNumber,
        };

        var endpoint = atob(this.attributes.endpoint.value);

        // var formData = new FormData(this);
        // console.log(formData);
        for (var i = 0; i < this.length; i++) {
          if (!this[i].name) continue;
          // console.log(i, '->', atob(this[i].name), this[i].value);
          jsonPayload[atob(this[i].name)] = this[i].value;
        }
        jsonPayload.phone = user.phoneNumber;

        var dt = new Date();
        var mons = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dts = {
          dd: dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate(),
          mm: mons[dt.getMonth()],
          yy: dt.getFullYear(),
          hh: dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours(),
          mn: dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes(),
          ss: dt.getSeconds() < 10 ? "0" + dt.getSeconds() : dt.getSeconds(),
        };
        jsonPayload.Date = `${dts.dd}-${dts.mm}-${dts.yy} ${dts.hh}:${dts.mn}:${dts.ss}`;
        jsonPayload.createdAt = surveyCreateTime;
        jsonPayload.updatedAt = parseInt(dt.getTime() / 1000);
        jsonPayload.timestamp_float = dt.getTime();
        // console.log(jsonPayload);
        // console.log(JSON.stringify(jsonPayload));
        // console.log(endpoint, atob(endpoint));

        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :4.1", y.responseText);
              toast.error("Error Occured " + a.message);
              // quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.message}</div>`;
              submitLoader.style.display = "none";
              quizList.style.display = "flex";
              submitBtn.style.display = "initial";
              return 0;
            } else {
              toast.success("Success " + a.message);
              quizList.innerHTML = `<div class="${styles.no_error}">Success <br/>  ${a.message}</div>`;
              submitLoader.style.display = "none";
              quizList.style.display = "flex";
              return 0;
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
            submitLoader.style.display = "none";
            quizList.style.display = "flex";
            submitBtn.style.display = "initial";
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
          toast.error("Error Occured! Try again");
          submitLoader.style.display = "none";
          quizList.style.display = "flex";
          submitBtn.style.display = "initial";
        };

        y.open("POST", endpoint);
        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send(JSON.stringify(jsonPayload));
      });

      getSurveyQuestions(menuTxt);
      function getSurveyQuestions(menuTxt) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            } else if (!a.data || typeof a.data != "object") {
              console.log("load Error > :3a", y.responseText);
              toast.error("Error Occured " + "No data");
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  No data</div>`;
              return 0;
            } else if (
              !a.data.endpointLink ||
              typeof a.data.subMenus != "object" ||
              typeof a.data.text != "object"
            ) {
              console.log("load Error > :3b", y.responseText);
              toast.error("Error Occured " + "Invalid data");
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/> Invalid data</div>`;
              return 0;
            } else if (!a.data.active) {
              console.log("load Error > :3b", y.responseText);
              toast.error(
                "Error Occured " +
                "Survey is not open. Check back at the end of the quarter"
              );
              quizList.innerHTML = `<div class="${styles.error}">Error Occured <br/> Survey is not open. Check back at the end of the quarter</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            // var playbookSelectedPair = JSON.parse(localStorage.getItem('playbookSelectedPair'));
            // if (!playbookSelectedPair) playbookSelectedPair = { _id: false };
            // a = a.data;
            surveyCreateTime = parseInt(Date.now() / 1000);
            frm.setAttribute("endpoint", btoa(a.data.endpointLink));
            a = a.data.subMenus;
            // a.push({ "code": "1", "text": { "en": "My business sells", "sw": "Biashara yangu inauza" }, "options": { "1": { "en": "Product", "sw": "Bidhaa" }, "2": { "en": "Services", "sw": "Huduma" }, "3": { "en": "Both", "sw": "Zote mbili" } }, "name": "offerings", "input": null, "active": true });
            // console.log(a);
            var listTpl = "";
            var yy = 0;
            var zz = 0;
            var xx = 0;
            for (var x in a) {
              // var xx = x;
              // for (var c in a[x].assignedPlaybooks) {
              //   xx = a[x].assignedPlaybooks[c];
              //   break;
              // }
              // console.log(xx, x);

              if (typeof a[x]["dependent"] == "object") {
                zz = xx ? zz : 0;
                zz++;
                xx = 1;
              } else {
                yy++;
                xx = 0;
              }

              if (typeof a[x]["options"] == "object") {
                var availOptions = '<option value="" selected="1" disabled="1">-- Select --</option>';
                for (var bb in a[x]["options"]) {
                  availOptions += `<option value="${bb}">${a[x]["options"][bb][surveyLang]}</option>`;
                }
                listTpl += tpl.quizListOpt
                  .replace(
                    /{quiz}/gi,
                    `<g>${typeof a[x]["dependent"] == "object" ? yy + "~" + zz : yy
                    }.</g> ${a[x]["text"][surveyLang].replace(/\n/g, "<br/>")}`
                  )
                  .replace(/{name}/gi, btoa(a[x]["name"]))
                  .replace(
                    /{val}/gi,
                    a[x]["active"]
                      ? typeof a[x]["dependent"] == "object"
                        ? `<option value="---skipped---" selected="1" disabled="1">Skip(N/A)</option>${availOptions.replace('<option value="" selected="1" disabled="1">-- Select --</option>', '')}`
                        : availOptions
                      : '<option value="---inactive---" selected="1">Inactive</option>'
                  )
                  .replace(
                    /{hide}/gi,
                    typeof a[x]["dependent"] == "object" && a[x]["dependent"].code
                      ? styles.quizListHide
                      : ""
                  )
                  .replace(/{id}/gi, "id_" + a[x]["code"])
                  .replace(/{code}/gi, a[x]["code"])
                  .replace(
                    /{opt}/gi,
                    typeof a[x]["dependent"] == "object"
                      ? styles.quizListoptional
                      : ""
                  );
              } else {
                listTpl += tpl.quizList
                  .replace(
                    /{quiz}/gi,
                    `<g>${typeof a[x]["dependent"] == "object" ? yy + "~" + zz : yy
                    }.</g> ${a[x]["text"][surveyLang].replace(/\n/g, "<br/>")}`
                  )
                  .replace(/{name}/gi, btoa(a[x]["name"]))
                  .replace(
                    /{val}/gi,
                    a[x]["active"]
                      ? typeof a[x]["dependent"] == "object"
                        ? "---skipped---"
                        : ""
                      : "---inactive---"
                  )
                  .replace(
                    /{hide}/gi,
                    typeof a[x]["dependent"] == "object" && a[x]["dependent"].code
                      ? styles.quizListHide
                      : ""
                  )
                  .replace(/{id}/gi, "id_" + a[x]["code"])
                  .replace(/{code}/gi, a[x]["code"])
                  .replace(
                    /{opt}/gi,
                    typeof a[x]["dependent"] == "object"
                      ? styles.quizListoptional
                      : ""
                  );
              }
              if (
                typeof a[x]["dependent"] == "object" &&
                a[x]["dependent"].code
              ) {
                // dependents[a[x]['dependent'].code] = {
                //   quizId: ('id_' + a[x]['code']),
                //   valueIs: a[x]['dependent'].input,
                //   quizCode: a[x]['code'],
                //   dependsOnCode: a[x]['dependent'].code
                // }
                if (!dependents[a[x]["dependent"].code])
                  dependents[a[x]["dependent"].code] = [];
                dependents[a[x]["dependent"].code].push({
                  quizId: "id_" + a[x]["code"],
                  valueIs: a[x]["dependent"].input,
                  quizCode: a[x]["code"],
                  dependsOnCode: a[x]["dependent"].code,
                });
              }
            }
            // console.log('dependents ->', dependents);

            quizList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">No Questions <br/> Survey Questions not available</div>`;

            var textInputs = quizList.getElementsByTagName("textarea");
            var selectInputs = quizList.getElementsByTagName("select");
            textInputs = [...textInputs, ...selectInputs];
            for (var i = 0; i < textInputs.length; i++) {
              textInputs[i].addEventListener("input", function () {
                this.style.height = "auto";
                this.style.height = this.scrollHeight + 5 + "px";
                if (dependents[this.attributes.code.value]) {
                  dependents[this.attributes.code.value].forEach((aa) => {
                    // var dpt = dependents[this.attributes.code.value];
                    // console.log('aa', aa);
                    var dpt = aa;
                    var checkVals = dpt.valueIs;
                    // console.log('Has a dependant -> ', dpt);
                    if (typeof dpt.valueIs != "object")
                      checkVals = [dpt.valueIs];
                    if (checkVals.includes(this.value.trim())) {
                      document.getElementById(dpt.quizId).style.display =
                        "flex";
                      document.getElementById(dpt.quizId).style.height = "0px";

                      if (document.getElementById(dpt.quizId).getElementsByTagName("textarea").length) {
                        document
                          .getElementById(dpt.quizId)
                          .getElementsByTagName("textarea")[0].value = "";
                      } else {
                        document
                          .getElementById(dpt.quizId)
                          .getElementsByTagName("select")[0].value = "";
                      }

                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height =
                          "112px";
                        document.getElementById(dpt.quizId).style.opacity = "1";
                      }, 10);
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height =
                          "initial";
                        document.getElementById(dpt.quizId).style.overflow =
                          "initial";
                      }, 270);
                    } else {
                      document.getElementById(dpt.quizId).style.height =
                        document.getElementById(dpt.quizId).scrollHeight + "px";
                      document.getElementById(dpt.quizId).style.overflow =
                        "hidden";
                      if (document.getElementById(dpt.quizId).getElementsByTagName("textarea").length) {
                        document
                          .getElementById(dpt.quizId)
                          .getElementsByTagName("textarea")[0].value =
                          "---skipped---";
                      } else {
                        document
                          .getElementById(dpt.quizId)
                          .getElementsByTagName("select")[0].value =
                          "---skipped---";
                      }

                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.height =
                          "0px";
                        document.getElementById(dpt.quizId).style.opacity = "0";
                      }, 10);
                      setTimeout(() => {
                        document.getElementById(dpt.quizId).style.display =
                          "none";
                      }, 270);
                    }
                  });
                }
              });

              textInputs[i].addEventListener("focus", function () {
                this.parentElement.parentElement.parentElement.classList.add(
                  styles.quizListActive
                );
              });

              textInputs[i].addEventListener("blur", function () {
                this.parentElement.parentElement.parentElement.classList.remove(
                  styles.quizListActive
                );
              });
            }
            submitBtn.style.display = "initial";
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        var reqPayload = {
          userId: user.user_id,
          email: user.email,
          menuId: "digikua",
          asRawMenu: true,
          lang: "en",
          text: menuTxt,
        };

        y.open("POST", globalVars.baseUrl3 + globalVars.surveyMenuRead);
        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send(JSON.stringify(reqPayload));
      }
    }

    if (document.getElementById("advisorsList")) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var advisorsList = document.getElementById("advisorsList");
      advisorsList.innerHTML = tpl.loaderMain;

      getPairedAdvisors(user.user_id);
      function getPairedAdvisors(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              advisorsList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            var playbookSelectedPair = JSON.parse(
              localStorage.getItem("playbookSelectedPair")
            );
            if (!playbookSelectedPair) playbookSelectedPair = { _id: false };
            a = a.data;
            var listTpl = "";
            for (var x in a) {
              var xx = x;
              for (var c in a[x].assignedPlaybooks) {
                xx = a[x].assignedPlaybooks[c];
                break;
              }
              // console.log(xx);
              listTpl += tpl.advisorsList
                .replace(/{pairId}/gi, a[x]._id)
                .replace(/{name}/gi, `Advisor ${parseInt(x) + 1}`)
                .replace(/{playbook}/gi, xx.name)
                .replace(/{email}/gi, a[x].advisorMail)
                .replace(/{phone}/gi, a[x].advisorWaap)
                .replace(
                  /{checkIcon}/gi,
                  playbookSelectedPair._id === a[x]._id
                    ? tpl.iconChecked
                    : tpl.iconNonChecked
                );
            }

            advisorsList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">Not Paired <br/>  You don't have an advisor yet, reach out to your business coach for pairing</div>`;
            var advisorsListChild =
              advisorsList.getElementsByClassName("advisor-list-each");
            for (var b of advisorsListChild) {
              b.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                // console.log('Clicked -> ', this.attributes.pairid.value, this);
                var backup = {
                  htm: this.innerHTML,
                  elm: this,
                };
                this.innerHTML +=
                  tpl.iconCheckedHover +
                  `<b class="${styles.iconLoader}">${tpl.loader}</b>`;

                // fetch('/').then((ers) => { return (ers.text()); }).then((e) => { console.log(e) })
                // fetch('/').then(x => x.text()).then((y) => { console.log(y) });
                // fetch('/').then(x => x.json()).then((y) => { console.log(y) });

                fetch(
                  `${globalVars.baseUrl}${globalVars.getPairedAdvisors}?id=${this.attributes.pairid.value}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${resolveToken()}`,
                    },
                  }
                )
                  .then((x) => x.json())
                  .then((z) => {
                    // console.log(z);
                    if (z.error) {
                      toast.error("Error Occured " + z.error);
                      backup.elm.innerHTML = backup.htm;
                      return 0;
                    } else if (!z.data[0]) {
                      toast.error("Error Occured. Pair not found  ");
                      backup.elm.innerHTML = backup.htm;
                      return 0;
                    } else if (z.data.length > 1) {
                      toast.error(
                        "Fatal Error. Too many pairs found, only one expected  "
                      );
                      backup.elm.innerHTML = backup.htm;
                      return 0;
                    }

                    delete z.data[0].whoAdded;
                    localStorage.setItem(
                      "playbookSelectedPair",
                      JSON.stringify(z.data[0])
                    );
                    setTimeout(
                      () => history.push("/dashboard/playbook/playbooks"),
                      900
                    );
                    // history.push("/dashboard/playbook/playbooks");
                  })
                  .catch((err) => {
                    console.log("Load Error > :3", err);
                    toast.error("Error Occured !");
                    backup.elm.innerHTML = backup.htm;
                  });
              });
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        y.open("GET", globalVars.baseUrl + globalVars.getPairedAdvisors);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    }

    if (document.getElementById("playbooksList")) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById("playbooksList");
      playbooksList.innerHTML = tpl.loaderMain;

      var playbookSelectedPair = JSON.parse(
        localStorage.getItem("playbookSelectedPair")
      );
      if (!playbookSelectedPair) {
        console.log("Not Paired > :3", playbookSelectedPair);
        toast.error("Not Paired. Select an advisor to proceed ");
        playbooksList.innerHTML = `<div class="${styles.error}">Not Paired <br/>  Select an <a href="/dashboard/playbook/advisors">advisor</a> to proceed</div>`;
        return 0;
      }
      var pairedPlaybooks = [];
      for (var i in playbookSelectedPair.assignedPlaybooks) {
        pairedPlaybooks.push(i);
      }

      getPairedPlaybooks(user.user_id);
      function getPairedPlaybooks(usrId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = "";
            for (var x in a) {
              var xx = x;
              for (var c in a[x].assignedPlaybooks) {
                xx = a[x].assignedPlaybooks[c];
                break;
              }
              if (!pairedPlaybooks.includes(xx.id)) {
                continue;
              }
              if (!a[x].progress) {
                a[x].progress = {
                  playbookId: xx._id,
                  chapterId: null,
                  sessionId: null,
                  state: "0%",
                  stateNumber: 0,
                  status: "pending",
                };
              }
              // console.log(xx);
              listTpl += tpl.playbooksList
                .replace(/{playbookId}/gi, xx.id)
                .replace(/{playbook}/gi, xx.name)
                .replace(
                  /{email}/gi,
                  a[x].advisorMail.charAt(0).toUpperCase() +
                  a[x].advisorMail.slice(1)
                )
                .replace(/{progress}/gi, a[x].progress.state)
                .replace(
                  /{status}/gi,
                  a[x].progress.status.charAt(0).toUpperCase() +
                  a[x].progress.status.slice(1)
                );
            }

            if (listTpl) {
              listTpl += `<div style="margin-bottom:14px;">Only showing playbooks that you can be taken through by the selected advisor. If you'd like to go through a different playbook, select another advisor / pairing</div>`;
            }
            playbooksList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">No Access to Playbook yet <br/>  You cannot access the playbooks unless you are paired with an advisor, reach out to your business coach for pairing</div>`;
            var playbooksListChild =
              playbooksList.getElementsByClassName("advisor-list-each");
            for (var b of playbooksListChild) {
              b.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(
                  () =>
                    history.push(
                      `/dashboard/playbook/playbook/${this.attributes.playbookId.value}`
                    ),
                  300
                );
              });
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        y.open("GET", globalVars.baseUrl + globalVars.getPairedAdvisors);
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    }

    if (document.getElementById("playbooksListChapter")) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById("playbooksListChapter");
      playbooksList.innerHTML = tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      var play_id_path = pathArr.pop();
      if (play_id_path.length != 24) {
        console.log("Bad id found, will retry inner", play_id_path, pathArr);

        var play_id_path = pathArr.pop();
        if (play_id_path.length != 24) {
          console.log("Bad id found 2, will kif up", play_id_path, pathArr);

          toast.error(
            "Selected Playbook detail not found. Select a playbook to proceed "
          );
          playbooksList.innerHTML = `<div class="${styles.error}">Selected Playbook detail not found. Select a playbook to proceed <br/>  Select a <a href="/dashboard/playbook/playbooks">Playbooks</a> to proceed</div>`;
          return 0;
        }
      }

      getPlaybookChapters(play_id_path);
      function getPlaybookChapters(playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = "";
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookChapterList
                .replace(/{chapterId}/gi, a[x]._id)
                .replace(/{name}/gi, a[x].name)
                .replace(/{pos}/gi, a[x].position); //.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">No Playbook chapters <br/>  try again later or reach out to your business coach for help</div>`;
            var playbooksListChild =
              playbooksList.getElementsByClassName("advisor-list-each");
            for (var b of playbooksListChild) {
              b.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(
                  () =>
                    history.push(
                      `/dashboard/playbook/book/${playbookId}/chapter/${this.attributes.chapterId.value}`
                    ),
                  300
                );
              });
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        y.open(
          "GET",
          globalVars.baseUrl +
          globalVars.getPlaybookChapters.replace(/{playbookId}/gi, playbookId)
        );
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    }

    if (document.getElementById("bookChaptListSession")) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbooksList = document.getElementById("bookChaptListSession");
      playbooksList.innerHTML = tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      var chapter_id_path = pathArr.pop();
      if (chapter_id_path.length != 24) {
        console.log("Bad id found, will retry inner", chapter_id_path, pathArr);

        var chapter_id_path = pathArr.pop();
        if (chapter_id_path.length != 24) {
          console.log("Bad id found 2, will kif up", chapter_id_path, pathArr);

          toast.error(
            "Selected Chapter detail not found. Select a playbook to proceed "
          );
          playbooksList.innerHTML = `<div class="${styles.error}">Selected Chapter detail not found. Select a playbook to proceed <br/>  Select a <a href="/dashboard/playbook/playbooks">Playbooks</a> to proceed</div>`;
          return 0;
        }
      }
      pathArr.pop();
      var play_id_path = pathArr.pop();

      getPlaybookSessions(chapter_id_path, play_id_path);
      function getPlaybookSessions(chapterId, playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = "";
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookSessionList
                .replace(/{sessionId}/gi, a[x]._id)
                .replace(/{name}/gi, a[x].name)
                .replace(/{pos}/gi, a[x].position); //.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`;
            var playbooksListChild =
              playbooksList.getElementsByClassName("advisor-list-each");
            for (var b of playbooksListChild) {
              b.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(
                  () =>
                    history.push(
                      `/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`
                    ),
                  300
                );
              });
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        y.open(
          "GET",
          globalVars.baseUrl +
          globalVars.getPlaybookSessions.replace(/{chapterId}/gi, chapterId)
        );
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    }

    if (document.getElementById("playbookSessionDetails")) {
      // return 0;
      // const element = <div>Hello {path}</div>;
      // console.log('HTML ->', renderToStaticMarkup(element), element)
      // console.log('HTML ->', renderToStaticMarkup(<FaUserShield />), FaUserShield)
      var playbookElm = {
        main: document.getElementById("playbookSessionDetails"),
        sesAside: document.getElementById("sesAside"),
        sesSection: document.getElementById("sesSection"),
        fetchTools: document.getElementById("fetchTools"),
        fetchAssign: document.getElementById("fetchAssign"),
        fetchFeedback: document.getElementById("fetchFeedback"),
        sesTtlTtl: document.getElementById("sesTtlTtl"),
        sesTtlInfo: document.getElementById("sesTtlInfo"),
        toolsCheckListTab: document.getElementById("toolsCheckListTab"),
        assignmentsTab: document.getElementById("assignmentsTab"),
        feedbacksTab: document.getElementById("feedbacksTab"),
        assignmentList: document.getElementById("assignmentList"),
        assignmentForm: document.getElementById("assignmentForm"),
        closeXformAsign: document.getElementById("closeXformAsign"),
        closeXformFeed: document.getElementById("closeXformFeed"),
        addFeedbackContainer: document.getElementById("addFeedbackContainer"),
        addFeedBtn: document.getElementById("addFeedBtn"),
        feedbackList: document.getElementById("feedbackList"),
        rateContainer: document.getElementById("rateContainer"),
      };

      playbookElm.sesTtlTtl.innerHTML = playbookElm.sesTtlInfo.innerHTML =
        tpl.loaderMain;

      var pathArr = location.pathname.split("/");
      if (pathArr.length != 9) {
        console.log("Bad url found, giving up...", location.pathname, pathArr);
        playbookElm.sesSection.innerHTML = `<div class="${styles.error}"><h1>Error!</h2><p>Link error occured. Refresh page or re-select <a href='/dashboard/playbook/playbooks'>playbook session</a> to continue</p></div>`;
        return 0;
      }

      var playbookSelectedPair = JSON.parse(
        localStorage.getItem("playbookSelectedPair")
      );
      if (!playbookSelectedPair) {
        console.log("No pairing info, giving up...", playbookSelectedPair);
        playbookElm.sesSection.innerHTML = `<div class="${styles.error}"><h1>Error!</h2><p>Pairing details error. Refresh page or re-select <a href='/dashboard/playbook/advisors'>playbook advisor</a> to continue</p></div>`;
        return 0;
      }

      var pathDetails = {
        bookId: pathArr[4],
        chapId: pathArr[6],
        sessId: pathArr[8],
      };
      // console.log(pathDetails);
      playbookElm.fetchTools.addEventListener("click", () => {
        switchToTab("toolsCheckListTab");
      });
      playbookElm.fetchAssign.addEventListener("click", () => {
        switchToTab("assignmentsTab");
      });
      playbookElm.fetchFeedback.addEventListener("click", () => {
        switchToTab("feedbacksTab");
      });

      playbookElm.closeXformAsign.addEventListener("click", () => {
        playbookElm.assignmentForm.classList.add(styles.hide);
      });
      playbookElm.closeXformFeed.addEventListener("click", () => {
        playbookElm.addFeedbackContainer.classList.add(styles.hide);
      });
      playbookElm.addFeedBtn.addEventListener("click", () => {
        playbookElm.addFeedbackContainer.classList.remove(styles.hide);
      });

      document.forms.feedbackForm.addEventListener("submit", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        var formPayload = new FormData(this);
        console.log(formPayload, formPayload.rated);

        if (!this.rated.value || this.rated.value == "0") {
          toast.error(
            "Rate the adviser by clicking any of the stars to continue"
          );
          playbookElm.rateContainer.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          return 0;
        }
        formPayload.append("sessionId", pathDetails.sessId);
        formPayload.append("pairingId", playbookSelectedPair._id);
        formPayload.append("from", "Entrepreneur");
        formPayload.append("addedById", user.user_id);
        formPayload.append("addedByEmail", user.email);
        addFeedback(this, formPayload);
      });

      document.forms.assignmentForm.addEventListener("submit", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        var formPayload = new FormData(this);
        console.log(formPayload, formPayload.rated);

        if (!this.response.value) {
          toast.error("Response is needed to submit");
          this.response.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          this.response.focus();
          return 0;
        }

        formPayload.append("addedById", user.user_id);
        formPayload.append("addedByEmail", user.email);
        addTaskResp(this, formPayload);
      });

      playbookElm.rateContainer.addEventListener("click", function (e) {
        if (e.target.tagName.toUpperCase() == "I") {
          var rt = parseInt(e.target.attributes.val.value.replace(/rt/gi, ""));
          document.forms.feedbackForm.rated.value = rt;
          var stars = this.getElementsByClassName(styles.ratingStar);

          e.target.style.transform = "scale(2)";
          var x = setTimeout(() => {
            e.target.style.transform = "scale(1)";
          }, 300);
          for (i = 0; i < stars.length; i++) {
            if (rt > i) {
              doStars(true, stars[i], i);
            } else {
              doStars(false, stars[i], 5 - i);
            }
          }

          function doStars(a, b, c) {
            var d = { a: "fa", o: "fa-regular" };

            if (a) {
              setTimeout(() => {
                b.classList.remove(d.o);
                b.classList.add(d.a);
              }, c * 100);
            } else {
              setTimeout(() => {
                b.classList.remove(d.a);
                b.classList.add(d.o);
              }, c * 100);
            }
          }
        }
      });

      function switchToTab(tab) {
        playbookElm.toolsCheckListTab.classList.add(styles.hide);
        playbookElm.assignmentsTab.classList.add(styles.hide);
        playbookElm.feedbacksTab.classList.add(styles.hide);

        playbookElm.fetchTools.classList.remove(styles.activeLnk);
        playbookElm.fetchAssign.classList.remove(styles.activeLnk);
        playbookElm.fetchFeedback.classList.remove(styles.activeLnk);

        if (tab == "toolsCheckListTab") {
          playbookElm.toolsCheckListTab.classList.remove(styles.hide);
          playbookElm.fetchTools.classList.add(styles.activeLnk);
        } else if (tab == "assignmentsTab") {
          playbookElm.assignmentsTab.classList.remove(styles.hide);
          playbookElm.fetchAssign.classList.add(styles.activeLnk);
        } else if (tab == "feedbacksTab") {
          playbookElm.feedbacksTab.classList.remove(styles.hide);
          playbookElm.fetchFeedback.classList.add(styles.activeLnk);
        }
      }

      getSessionDetails(pathDetails.sessId);
      function getSessionDetails(sessId) {
        try {
          fetch(
            globalVars.baseUrl +
            globalVars.getPBookSesInfo.replace(/{id}/gi, sessId),
            {
              headers: {
                Authorization: `Bearer ${resolveToken()}`,
              },
            }
          )
            .then((res) => res.json())
            .then((a) => {
              // var a = res.json();
              // console.log('Prom resp', a);
              if (a.error || !a.data || a.data.length != 1) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + a.error);
                playbookElm.sesTtlTtl.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
                playbookElm.sesTtlInfo.innerHTML = ``;
                return 0;
              }

              a = a.data[0];
              playbookElm.sesTtlTtl.innerHTML = tpl.sesTtlTtl
                .replace(/{pos}/i, a.position)
                .replace(/{ttl}/i, a.name);
              playbookElm.sesTtlInfo.innerHTML = a.info;

              // var listTpl = '';
              // for (var x in a) {
              //   // console.log(x);
              //   listTpl += tpl.playbookSessionList.replace(/{sessionId}/ig, a[x]._id).replace(/{name}/ig, a[x].name).replace(/{pos}/ig, a[x].position);//.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
              // }

              // playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
              // var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
              // for (var b of playbooksListChild) {
              //   b.addEventListener('click', function (e) {
              //     e.stopImmediatePropagation();
              //     this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
              //     setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
              //   });
              // }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }

        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      }

      getSessionTools(pathDetails.sessId, playbookSelectedPair._id);
      function getSessionTools(sessId, pairId) {
        try {
          fetch(
            globalVars.baseUrl +
            globalVars.getPBookToolNCheck
              .replace(/{sessId}/gi, sessId)
              .replace(/{pairId}/gi, pairId),
            {
              headers: {
                Authorization: `Bearer ${resolveToken()}`,
              },
            }
          )
            .then((res) => res.json())
            .then((a) => {
              // var a = res.json();
              // console.log('Prom resp', a);
              if (a.error || !a.data) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + a.error);
                playbookElm.toolsCheckListTab.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
                return 0;
              } else if (!a.data.length) {
                console.log("load Error > :4", a);
                playbookElm.toolsCheckListTab.innerHTML = `<div class="${styles.error}">Exeception Occured <br/>  No data available. Try again later</div>`;
                return 0;
              }

              var a = a.data;
              var listTpl = "";
              for (var x in a) {
                listTpl += tpl.sesToolList
                  .replace(/{info}/gi, a[x].info)
                  .replace(/{name}/gi, a[x].name)
                  .replace(/{img}/gi, a[x].icon)
                  .replace(/{value}/gi, a[x].value)
                  .replace(
                    /{statusIcon}/gi,
                    a[x]._isDone ? tpl.sesToolListDone : tpl.sesToolListWait
                  );
              }
              playbookElm.toolsCheckListTab.innerHTML = listTpl;

              // playbooksList.innerHTML = (listTpl ? listTpl : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`);
              // var playbooksListChild = playbooksList.getElementsByClassName('advisor-list-each');
              // for (var b of playbooksListChild) {
              //   b.addEventListener('click', function (e) {
              //     e.stopImmediatePropagation();
              //     this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
              //     setTimeout(() => history.push(`/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`), 300);
              //   });
              // }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }

        // y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
      }

      getSessionTasks(pathDetails.sessId, playbookSelectedPair._id);
      function getSessionTasks(sessId, pairId) {
        try {
          fetch(
            globalVars.baseUrl +
            globalVars.getPBookTasks
              .replace(/{sessId}/gi, sessId)
              .replace(/{pairId}/gi, pairId),
            {
              headers: {
                Authorization: `Bearer ${resolveToken()}`,
              },
            }
          )
            .then((res) => res.json())
            .then((a) => {
              // var a = res.json();
              // console.log('Prom resp', a);
              if (a.error || !a.data) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + a.error);
                playbookElm.assignmentList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
                return 0;
              } else if (!a.data.length) {
                console.log("load Error > :4", a);
                playbookElm.assignmentList.innerHTML = `<div class="${styles.error}">Nothing yet <br/>  No assignment available. Try again later</div>`;
                return 0;
              }

              var a = a.data;
              var listTpl = "";
              for (var x in a) {
                var tmpFiles = "";
                for (var b in a[x].files) {
                  tmpFiles += tpl.sesListFiles
                    .replace(
                      /{link}/gi,
                      "https://drive.google.com/file/d/" + a[x].files[b]
                    )
                    .replace(/{count}/gi, b + 1);
                }

                listTpl += tpl.sesTaskList
                  .replace(/{topic}/gi, a[x].topic)
                  .replace(
                    /{duedate}/gi,
                    new Date(parseFloat(a[x].dueDate)).toLocaleString("en-US", {
                      timeZone: "Africa/Nairobi",
                      dateStyle: "long",
                    })
                  )
                  .replace(/{task}/gi, a[x].task)
                  .replace(/{files}/gi, tmpFiles)
                  .replace(
                    /{id}/gi,
                    btoa(
                      JSON.stringify({
                        id: a[x]._id,
                        topic: a[x].topic,
                        due: a[x].dueDate,
                      })
                    )
                  )
                  .replace(/{hide}/gi, a[x].taskResp ? styles.hide : "")
                  .replace(
                    /{resdate}/gi,
                    a[x].date.response
                      ? new Date(parseFloat(a[x].date.response)).toLocaleString(
                        "en-US",
                        { timeZone: "Africa/Nairobi", dateStyle: "long" }
                      )
                      : "-"
                  );
              }
              // console.log(listTpl);
              playbookElm.assignmentList.innerHTML = listTpl;

              var listChildren =
                playbookElm.assignmentList.getElementsByClassName(
                  "start-task-reply-btn"
                );
              for (var b of listChildren) {
                b.addEventListener("click", function (e) {
                  e.stopImmediatePropagation();
                  console.log("id", this.id); //`<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                  var payload = JSON.parse(atob(this.id));
                  var frm = document.forms.assignmentForm;
                  frm.id.value = payload.id;
                  frm.dueDate.value = new Date(
                    parseFloat(payload.due)
                  ).toLocaleString("en-US", {
                    timeZone: "Africa/Nairobi",
                    dateStyle: "long",
                  });
                  frm.topic.value = payload.topic;
                  playbookElm.assignmentForm.classList.remove(styles.hide);
                });
              }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }
      }

      getSessionFeeds(
        pathDetails.sessId,
        playbookSelectedPair._id,
        user.user_id
      );
      function getSessionFeeds(sessId, pairId, usrId) {
        try {
          fetch(
            globalVars.baseUrl +
            globalVars.getPBookFeeds
              .replace(/{sessId}/gi, sessId)
              .replace(/{usrId}/gi, usrId),
            {
              headers: {
                Authorization: `Bearer ${resolveToken()}`,
              },
            }
          )
            .then((res) => res.json())
            .then((a) => {
              // var a = res.json();
              // console.log('Prom resp', a);
              if (a.error || !a.data) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + a.error);
                playbookElm.feedbackList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
                return 0;
              } else if (!a.data.length) {
                console.log("load Error > :4", a);
                playbookElm.feedbackList.innerHTML = `<div class="${styles.error}">Nothing yet <hr/>  No feedback for this session is given yet. <br/>When an advisor gives you feedback, you'll see it here</div>`;
                return 0;
              }

              var a = a.data;
              var listTpl = "";
              for (var x in a) {
                var tmpFiles = "";
                for (var b in a[x].files) {
                  tmpFiles += tpl.sesListFiles
                    .replace(
                      /{link}/gi,
                      "https://drive.google.com/file/d/" + a[x].files[b]
                    )
                    .replace(/{count}/gi, b + 1);
                }

                listTpl += tpl.sesFeedList
                  .replace(/{feed}/gi, a[x].feedback)
                  .replace(
                    /{advisor}/gi,
                    `${a[x].from} (${a[x].whoAdded.email})`
                  )
                  .replace(/{files}/gi, tmpFiles);
              }
              // console.log(listTpl);
              playbookElm.feedbackList.innerHTML = listTpl;

              // var listChildren = playbookElm.assignmentList.getElementsByClassName('start-task-reply-btn');
              // for (var b of listChildren) {
              //   b.addEventListener('click', function (e) {
              //     e.stopImmediatePropagation();
              //     console.log('id', this.id); //`<b class="${styles.iconLoader}">${tpl.loader}</b>`;
              //     var payload = JSON.parse(atob(this.id));
              //     var frm = document.forms.assignmentForm;
              //     frm.id.value = payload.id;
              //     frm.dueDate.value = new Date(parseFloat(payload.due)).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', dateStyle: 'long' });
              //     frm.topic.value = payload.topic;
              //     playbookElm.assignmentForm.classList.remove(styles.hide);
              //   });
              // }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }
      }

      function addFeedback(frm, payload) {
        try {
          frm.submit.innerHTML = `${renderToStaticMarkup(
            <LoaderIntro size="1em" color="#fff" class={styles.icon} />
          )}`;
          fetch(globalVars.baseUrl2 + globalVars.addPBookFeed, {
            headers: {
              // "Authorization": `Bearer ${resolveToken()}`
            },
            body: payload,
            method: "POST",
          })
            .then((res) => res.json())
            .then((a) => {
              console.log("Prom resp -> ", a);
              frm.submit.innerHTML = `Submit`;
              if (a.error) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + (a.msg ? a.msg : a.error));
                return 0;
              } else {
                toast.success(a.msg);
                frm.reset();
                document.getElementById("resetRatingStar").click();
                return 0;
              }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }
      }

      function addTaskResp(frm, payload) {
        try {
          frm.submit.innerHTML = `${renderToStaticMarkup(
            <LoaderIntro size="0.9em" color="#fff" class={styles.icon} />
          )}`;
          fetch(globalVars.baseUrl2 + globalVars.addTaskResp, {
            headers: {
              // "Authorization": `Bearer ${resolveToken()}`
            },
            body: payload,
            method: "POST",
          })
            .then((res) => res.json())
            .then((a) => {
              console.log("Prom resp -> ", a);
              frm.submit.innerHTML = `Submit`;
              if (a.error) {
                console.log("load Error > :3", a);
                toast.error("Error Occured " + (a.msg ? a.msg : a.error));
                return 0;
              } else {
                toast.success(a.msg);
                frm.reset();
                return 0;
              }
            });
        } catch (er) {
          console.log("load Error > :2", er);
          toast.error("Error Occured !");
        }
      }

      // getPlaybookSessions(chapter_id_path, play_id_path);
      function getPlaybookSessions(chapterId, playbookId) {
        var y = new XMLHttpRequest();
        y.onload = function () {
          try {
            var a = JSON.parse(y.responseText);
            if (a.error) {
              console.log("load Error > :3", y.responseText);
              toast.error("Error Occured " + a.error);
              playbooksList.innerHTML = `<div class="${styles.error}">Error Occured <br/>  ${a.error}</div>`;
              return 0;
            }
            // console.log('paired --a--> ', a);

            a = a.data;
            var listTpl = "";
            for (var x in a) {
              // console.log(x);
              listTpl += tpl.playbookSessionList
                .replace(/{sessionId}/gi, a[x]._id)
                .replace(/{name}/gi, a[x].name)
                .replace(/{pos}/gi, a[x].position); //.replace(/{progress}/ig, a[x].progress).replace(/{status}/ig, a[x].progress);
            }

            playbooksList.innerHTML = listTpl
              ? listTpl
              : `<div class="${styles.error}">No Playbook sessions <br/>  try again later or reach out to your business coach for help</div>`;
            var playbooksListChild =
              playbooksList.getElementsByClassName("advisor-list-each");
            for (var b of playbooksListChild) {
              b.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                this.innerHTML += `<b class="${styles.iconLoader}">${tpl.loader}</b>`;
                setTimeout(
                  () =>
                    history.push(
                      `/dashboard/playbook/b/${playbookId}/c/${chapterId}/s/${this.attributes.sessionId.value}`
                    ),
                  300
                );
              });
            }
          } catch (er) {
            console.log("load Error > :2", y.responseText, er);
            toast.error("Error Occured !");
          }
        };
        y.onerror = function () {
          console.log("load Error > :1", y.responseText);
        };
        y.open(
          "GET",
          globalVars.baseUrl +
          globalVars.getPlaybookSessions.replace(/{chapterId}/gi, chapterId)
        );
        y.setRequestHeader("Authorization", `Bearer ` + resolveToken());
        y.send();
      }
    }
  });

  // useEffect(() => {
  //   retrieveClientLoans();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      clearError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const placeholderFun = (data) => {
    // loanApply(data, user.user_id);
  };

  const calculateLoan = (data) => {
    loanApply(data, user.user_id);
  };

  const updateAddress = (data) => {
    addAddressForLoan(data, currentLoanId);
  };

  const updateEmployerInfo = (data) => {
    addWorkInfoForLoan(data, currentLoanId);
  };

  const updateBankInfo = (data) => {
    addBankInfoForLoan(data, currentLoanId);
  };

  const resumeApplication = () => {
    clearCompleteState();
    if (loanApplicationStage === "calculated") {
      history.push("/dashboard/consumer-credit/apply/contact-info");
    }
    if (loanApplicationStage === "address_added") {
      history.push("/dashboard/consumer-credit/apply/employer-info");
    }
    if (loanApplicationStage === "employer_added") {
      history.push("/dashboard/consumer-credit/apply/bank-info");
    }
  };

  function handlePreSetsForm() {
    var usrLoc = document.getElementById("locationValue").value;
    if (!usrLoc) {
      document.getElementById("preSetsInfo").innerText =
        "Select the location of the hub closest to you";
      toast.error("Select the location of the hub closest to you");
      return 0;
    }
    document.getElementById("preSetsLoader").style.display = "inline";
    document.getElementById("preSetsLoaderWait").style.display = "flex";
    document.getElementById("preSetsInfo").innerText = "";
    var tmt = setTimeout(function () {
      window.location.reload();
    }, 1000 * 60 * 3);
    var x = new XMLHttpRequest();
    x.onload = function () {
      document.getElementById("preSetsLoader").style.display = "none";
      document.getElementById("preSetsLoaderWait").style.display = "none";
      try {
        clearTimeout(tmt);
      } catch (er) {
        console.log(er);
      }
      try {
        var a = JSON.parse(x.responseText);
        if (!a.status) {
          console.log("load Error > :3", x.responseText);
          toast.error("Error!! " + a.message);
          document.getElementById("preSetsInfo").innerText = a.error
            ? a.error
            : "Error Occured";
        } else {
          document.getElementById("preSetsParent").style.display = "none";
          window.location.reload();
        }
        console.log("res", a);
      } catch (er) {
        console.log("load Error > :2", x.responseText, er);
        toast.error("Error!! " + er);
        document.getElementById("preSetsInfo").innerText = "Error Occured" + er;
      }
    };
    x.onerror = function () {
      try {
        clearTimeout(tmt);
      } catch (er) {
        console.log(er);
      }
      console.log("load Error > :1", x.responseText);
      toast.error("Error! Try again");
      document.getElementById("preSetsInfo").innerText = "Error! Try again";
      document.getElementById("preSetsLoader").style.display = "none";
      document.getElementById("preSetsLoaderWait").style.display = "none";
    };
    x.open(
      "GET",
      globalVars.baseUrl +
      globalVars.getApplicantState.replace(":user_id", user.user_id) +
      "?location=" +
      usrLoc
    );
    x.setRequestHeader("Authorization", `Bearer ` + resolveToken());
    x.send();
  }

  return (
    <>
      <Dashboard
        sidebarRoutes={
          initialPath === applicantBasePath ? applicRoutes : clientRoutes
        }
        location={location}
      >
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <nav>
            {/* <a clicked={() => history.push('/dashboard/playbook/home')}>Hey</a> */}
            <Button
              bgColor={
                window.location.href.split("/").pop() == "home"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              className="mt-4"
              clicked={() =>
                history.push(
                  `${initialPath === applicantBasePath
                    ? applicantPath
                    : clientPath
                  }home`
                )
              }
            >
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button
              bgColor={
                window.location.href.split("/").pop() == "quarterly_survey"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              className="mt-4"
              clicked={() =>
                history.push(
                  `${initialPath === applicantBasePath
                    ? applicantPath
                    : clientPath
                  }quarterly_survey`
                )
              }
            >
              <FaBusinessTime />{" "}
              <span className={styles.btnTxt}>Quarterly Survey</span>
            </Button>
            <Button
              bgColor={
                window.location.href.split("/").pop() == "post_quarterly_survey"
                  ? "#00a5a2"
                  : "#cc6328"
              }
              size="sm"
              color="#fff"
              className="mt-4"
              clicked={() =>
                history.push(
                  `${initialPath === applicantBasePath
                    ? applicantPath
                    : clientPath
                  }post_quarterly_survey`
                )
              }
            >
              <FaTimeline />{" "}
              <span className={styles.btnTxt}>Post Quarterly Survey</span>
            </Button>
          </nav>
        </div>
        <Switch>
          <Route path={`${path}/home`}>
            <div
              className={styles.preSets}
              id="preSetsParentB"
              style={{ display: "none" }}
            >
              <div>
                <span
                  id="preSetsLoaderA"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                  }}
                >
                  Loading...&nbsp;
                  <LoaderPre />
                </span>
                <form
                  id="preSetsForm"
                  style={{ display: "none" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePreSetsForm();
                  }}
                >
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select
                      id="locationValue"
                      name="location"
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <option value="" selected={true} disabled={true}>
                        Select Location
                      </option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreSetsForm();
                    }}
                  >
                    Save & Continue
                    <span id="preSetsLoader" style={{ display: "none" }}>
                      <LoaderIntro />
                    </span>
                  </button>
                  <div
                    id="preSetsLoaderWait"
                    className={styles.preSetsWait}
                    style={{ display: "nones" }}
                  >
                    <LoaderIntroWait />
                    <p>
                      <b>Setting Up Your Somo Space...</b>
                      <br />
                      This may take a few minutes (2 to 5 mins)
                      <br />
                      Be patient and do not refresh the page.
                      <br />
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div
              className={styles.creditTable}
              ids="mainWelcomePageHolder"
              id="myEntrepreneursContainer"
            >
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Quarterly Surveys</h2>
                <hr />
                <p>
                  Hi <g id="usr_name_salt">there</g>, <br />
                  We're glad to have you as part of us.
                  <br />
                  Take some time to fill out the survey.
                  <br />
                  You'll only fill it once every quarter.
                  <br />
                  Fill the survey that best represents you.
                  <br />
                  <br />
                  <strong>
                    <i>Available Surveys*:</i>
                  </strong>
                  <br />
                </p>
                <div className={styles.surveyList}>
                  <h3>Language / Lugha</h3>
                  <p>
                    Select your preffered survey language
                    <br />
                    Chagua lugha ya uchunguzi unayopendelea
                  </p>
                  <select
                    id="survey_language"
                    className={styles.surveyLanguage}
                  >
                    <option value={"en"}>English</option>
                    <option value={"sw"}>Kiswahili</option>
                  </select>
                </div>
                <div className={styles.surveyList}>
                  <h3>Quarterly Survey</h3>
                  <p>For all entrepreneurs within 2 years</p>
                  <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    className={`mt-4 ${styles.btn}`}
                    clicked={() =>
                      history.push(
                        `${initialPath === applicantBasePath
                          ? applicantPath
                          : clientPath
                        }quarterly_survey`
                      )
                    }
                  >
                    Take Survey <FaBusinessTime size="1.4em" />
                  </Button>
                </div>
                <div className={styles.surveyList}>
                  <h3>Post Quarterly Survey</h3>
                  <p>
                    For all entrepreneurs past 2 years with no active financial
                    support
                  </p>
                  <Button
                    bgColor="#cc6328"
                    size="sm"
                    color="#fff"
                    className="mt-4"
                    clicked={() =>
                      history.push(
                        `${initialPath === applicantBasePath
                          ? applicantPath
                          : clientPath
                        }post_quarterly_survey`
                      )
                    }
                  >
                    Take Survey <FaTimeline size="1.4em" />
                  </Button>
                </div>
              </div>
            </div>
          </Route>
          <Route path={`${path}/quarterly_survey`}>
            <div className={styles.applicationComplete}>
              <h2>
                <FaBusinessTime size="2em" color="#cc6328" /> Quarterly Survey
              </h2>
              <p style={{ textAlign: "left" }}>
                {" "}
                Answer all questions
                <br />
              </p>
              <form method="POST" name="q_survey_form" endpoint="">
                <div
                  className={styles.quizListHold}
                  style={{ textAlign: "left" }}
                  id="quizList"
                  survey_info="quarterly_survey"
                >
                  {/* <div className={styles.quizList}>
                    <div className={styles.quizListInfo}>
                      <label>
                        <p>Select your country<br />1.Kenya<br />2.Tanzania</p>
                        <textarea rows={1} id="autoresizing" name="" required="1"></textarea>
                      </label>
                    </div>
                  </div> */}
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100px",
                    }}
                  >
                    Loading...&nbsp;
                    <LoaderIntro color="#00a5a2" />
                  </span>
                </div>
                <div className={styles.quizBtnLdr}>
                  <button
                    id="surveySubmitBtn"
                    type="submit"
                    className={styles.surveySubmitBtn}
                  >
                    Submit&nbsp;
                    <FaCloudUploadAlt size="2em" color="#00a5a2" />
                  </button>
                  <span
                    id="surveySubmitLoader"
                    className={styles.surveySubmitLoader}
                    style={{
                      /*display: 'flex',*/ flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100px",
                      fontSize: "2em",
                    }}
                  >
                    Loading...&nbsp;
                    <LoaderIntroWait size="200px" color="#00a5a2" />
                  </span>
                </div>
              </form>
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
          <Route path={`${path}/post_quarterly_survey`}>
            <div className={styles.applicationComplete}>
              <h2>
                <FaTimeline size="2em" color="#cc6328" /> Post Quarterly Survey
              </h2>
              <p style={{ textAlign: "left" }}>
                {" "}
                Answer all questions
                <br />
              </p>
              <form method="POST" name="q_survey_form" endpoint="">
                <div
                  className={styles.quizListHold}
                  style={{ textAlign: "left" }}
                  id="quizList"
                  survey_info="post_quarterly_survey"
                >
                  {/* <div className={styles.quizList}>
                    <div className={styles.quizListInfo}>
                      <label>
                        <p>Select your country<br />1.Kenya<br />2.Tanzania</p>
                        <textarea rows={1} id="autoresizing" name="" required="1"></textarea>
                      </label>
                    </div>
                  </div> */}
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100px",
                    }}
                  >
                    Loading...&nbsp;
                    <LoaderIntro color="#00a5a2" />
                  </span>
                </div>
                <div className={styles.quizBtnLdr}>
                  <button
                    id="surveySubmitBtn"
                    type="submit"
                    className={styles.surveySubmitBtn}
                  >
                    Submit&nbsp;
                    <FaCloudUploadAlt size="2em" color="#00a5a2" />
                  </button>
                  <span
                    id="surveySubmitLoader"
                    className={styles.surveySubmitLoader}
                    style={{
                      /*display: 'flex',*/ flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100px",
                      fontSize: "2em",
                    }}
                  >
                    Loading...&nbsp;
                    <LoaderIntroWait size="200px" color="#00a5a2" />
                  </span>
                </div>
              </form>
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
          <Route path={`${path}/playbook/:play_id`}>
            <div className={styles.applicationComplete}>
              <h2>
                <FaBookReader size="1.2em" color="#cc6328" /> My Playbooks
              </h2>
              <nav className={styles.playbookNav}>
                <Button
                  bgColor={
                    window.location.href.split("/").pop() == "playbooks"
                      ? "#00a5a2"
                      : "#cc6328"
                  }
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() => history.push("/dashboard/playbook/playbooks")}
                >
                  <FaArrowCircleLeft />
                </Button>
                <div>Playbook {">"} Chapters</div>
              </nav>
              <p style={{ textAlign: "left" }}>
                {" "}
                Select chapter
                <br />
              </p>
              <div
                className={styles.advisorsListHold}
                style={{ textAlign: "left" }}
                id="playbooksListChapter"
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  Loading...&nbsp;
                  <LoaderIntro color="#00a5a2" />
                </span>
              </div>
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
          <Route path={`${path}/book/:play_id/chapter/:chap_id`}>
            <div className={styles.applicationComplete}>
              <h2>
                <FaBookReader size="1.2em" color="#cc6328" /> My Playbooks
              </h2>
              <nav className={styles.playbookNav}>
                <Button
                  bgColor={
                    window.location.href.split("/").pop() == "playbooks"
                      ? "#00a5a2"
                      : "#cc6328"
                  }
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() => history.push("/dashboard/playbook/playbooks")}
                >
                  <FaArrowCircleLeft />
                </Button>
                <div>
                  Playbook {">"} Chapter {">"} Sessions
                </div>
              </nav>
              <p style={{ textAlign: "left" }}>
                {" "}
                Select a session
                <br />
              </p>
              <div
                className={styles.advisorsListHold}
                style={{ textAlign: "left" }}
                id="bookChaptListSession"
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100px",
                  }}
                >
                  Loading...&nbsp;
                  <LoaderIntro color="#00a5a2" />
                </span>
              </div>
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
          <Route path={`${path}/b/:play_id/c/:chap_id/s/:ses_id`}>
            <div className={styles.sesInfo} id="playbookSessionDetails">
              <h2>
                <FaBookReader size="1.2em" color="#cc6328" /> My Playbooks
              </h2>
              <nav className={styles.playbookNav}>
                <Button
                  bgColor={
                    window.location.href.split("/").pop() == "playbooks"
                      ? "#00a5a2"
                      : "#cc6328"
                  }
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() => history.push("/dashboard/playbook/playbooks")}
                >
                  <FaArrowCircleLeft />
                </Button>
                <div>
                  Playbook {">"} Chapter {">"} Sessions {">"} Session Details
                </div>
              </nav>
              <main className={styles.sesMain}>
                <aside className={styles.sesAside} id="sesAside">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100px",
                    }}
                  >
                    Loading Session list ...&nbsp;
                    <LoaderIntro color="#00a5a2" />
                  </span>
                </aside>
                <section className={styles.sesSection} id="sesSection">
                  <div className={styles.generalPlaybookContainer}>
                    <div className={styles.navLinks}>
                      <a
                        className={`${styles.reportNavLink} ${styles.activeLnk}`}
                        id="fetchTools"
                      >
                        Tools
                      </a>
                      <a className={styles.reportNavLink} id="fetchAssign">
                        Assignment
                      </a>
                      <a className={styles.reportNavLink} id="fetchFeedback">
                        Feedback
                      </a>
                    </div>

                    <div className={styles.titleInfo}>
                      <div className={`${styles.ttl}`} id="sesTtlTtl">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100px",
                          }}
                        >
                          Loading Content ...&nbsp;
                          <LoaderIntro color="#00a5a2" />
                        </span>
                        {/* <h3>1.</h3><h3>Effective &amp; timely reporting</h3> */}
                      </div>

                      <div className={`${styles.info}`}>
                        <p id="sesTtlInfo">
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: "100px",
                            }}
                          >
                            Loading Content ...&nbsp;
                            <LoaderIntro color="#00a5a2" />
                          </span>
                          {/* During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth. */}
                        </p>
                        <FaChalkboardTeacher size="4em" class={styles.icon} />
                      </div>
                    </div>

                    <div
                      className={`${styles.toolsCheckList}`}
                      id="toolsCheckListTab"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "100px",
                        }}
                      >
                        Loading Content ...&nbsp;
                        <LoaderIntro color="#00a5a2" />
                      </span>

                      {/* <div id="649a9124a2e8dd9af85c79c6" className={styles.toolCheck}>
                        <img src="https://invest.somoafrica.org/img/playbook/yt.png" />
                        <div className={styles.toolTtl}>
                          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">Tutorials on DigiKua</p>
                          <a class="subPlaybookListLinks" href="https://www.youtube.com/watch?v=anTfnD1dOVc&amp;list=PLfJScRTC4Bo0N4-AxnOYmX2PvnuSXq2j9" target="blank">Review DigiKua </a>
                        </div>
                        <div className={styles.checkStatus}>
                          <p>Pending...</p>
                          <FadeLoader size="4em" class={styles.icon} />
                        </div>
                      </div>


                      <div id="649a9125a2e8dd9af85c79c9" className={styles.toolCheck}>
                        <img src="https://invest.somoafrica.org/img/playbook/docs.png" />
                        <div className={styles.toolTtl}>
                          <p styles="padding-left: 10px;font-weight:bold;color:#CC6328;">Importance of timely reporting </p>
                          <a class="subPlaybookListLinks" href="https://docs.google.com/document/u/0/d/1KDXJsVWCKGvlfuB-hzzbjrSQV5NoLjPVIvsTk4-AAec/edit" target="blank">Review DigiKua </a>
                        </div>
                        <div className={styles.checkStatus}>
                          <p>Completed</p>
                          <FaCheckCircle size="4em" class={styles.icon} />
                        </div>
                      </div> */}
                    </div>

                    <div
                      className={`${styles.assignments} ${styles.hide}`}
                      id="assignmentsTab"
                    >
                      <div className={styles.taskList} id="assignmentList">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100px",
                          }}
                        >
                          Loading Content ...&nbsp;
                          <LoaderIntro color="#00a5a2" />
                        </span>

                        {/* <div className={styles.task}>
                          <div className={styles.top}>
                            <p><b>Topic : </b><span>Market Research</span></p>
                            <p><b>Due Date : </b><span>Aug 11 2023</span></p>
                          </div>
                          <textarea readonly="">Do During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth.+</textarea>
                          <div className={styles.bottom}>
                            <p className={styles.file}>
                              <b>Files : </b>
                              <a href="hey" target="_blank">File 1</a>
                              <a href="hey" target="_blank">File 2</a>
                              <a href="hey" target="_blank">File 3</a>
                            </p>
                            <button>Reply</button>
                          </div>
                        </div> */}
                      </div>

                      <div
                        className={`${styles.replyTask} ${styles.hide}`}
                        id="assignmentForm"
                      >
                        <form
                          action=""
                          method="POST"
                          className={styles.newAssignmentForm}
                          name="assignmentForm"
                        >
                          <span className={styles.close} id="closeXformAsign">
                            {" "}
                            Close X
                          </span>
                          <p className={styles.errMsg}></p>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label
                                className={styles.requiredDataAssignments}
                                for="topic"
                              >
                                Topic:
                              </label>
                              <input
                                type="text"
                                name="topic"
                                className={styles.topic}
                                id="topic"
                                readonly=""
                              />
                              <input type="hidden" name="id" />
                            </div>
                            <div className={styles.inputGrp}>
                              <label
                                className={styles.requiredDataAssignments}
                                for="dueDate"
                              >
                                Due Date:
                              </label>
                              <input
                                type="text"
                                name="dueDate"
                                id="dueDate"
                                readonly=""
                              />
                            </div>
                          </div>

                          <div className={styles.inputGrp}>
                            <label
                              className={styles.requiredDataAssignments}
                              for="task"
                            >
                              Answer:{" "}
                            </label>
                            <textarea
                              name="response"
                              id="task"
                              cols="20"
                              rows="10"
                              placeholder="Write the answer Here. If you need to submit a file, select it below"
                            ></textarea>
                          </div>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label for="file">
                                Additional File (Optional):
                              </label>
                              <input
                                type="file"
                                name="file[]"
                                id="file"
                                multiple="multiple"
                              />
                            </div>

                            <button
                              type="submit"
                              name="submit"
                              className={styles.assignBtn}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div
                      className={`${styles.feedbacks} ${styles.hide}`}
                      id="feedbacksTab"
                    >
                      <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
                      />
                      <div className={styles.add}>
                        <button id="addFeedBtn">
                          <FaMailBulk size="3em" className={styles.icon} />{" "}
                          <span>Add Feedback</span>
                        </button>
                      </div>

                      <div className={styles.feedList} id="feedbackList">
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100px",
                          }}
                        >
                          Loading Content ...&nbsp;
                          <LoaderIntro color="#00a5a2" />
                        </span>
                        {/* <div className={styles.feed}>
                          <FaUserShield size="5em" color="#cc6328" className={styles.icon} />
                          <div className={styles.left}>
                            <p>Playbook@somoafrica.org</p>
                            <div className={styles.msg}>Do During this module, you will work to understand the importance of effective and timely reporting, the tools available through Somo to make record-keeping easier, and how reports can be used for growth.+</div>
                            <p className={styles.file}>
                              <b>Files : </b>
                              <a href="hey" target="_blank">File 1</a>
                              <a href="hey" target="_blank">File 2</a>
                              <a href="hey" target="_blank">File 3</a>
                            </p>
                          </div>

                        </div> */}
                      </div>

                      <div
                        className={`${styles.addFeedback} ${styles.hide}`}
                        id="addFeedbackContainer"
                      >
                        <form
                          action=""
                          method="POST"
                          className={styles.feedbackForm}
                          id="feedbackForm"
                          name="feedbackForm"
                        >
                          <span className={styles.close} id="closeXformFeed">
                            Close X
                          </span>
                          <p className={styles.errMsg}></p>
                          <div className={styles.inputGrp}>
                            <label> Rate Advisor for this Session:</label>
                            <input type="hidden" name="rated" value="0" />
                            <div
                              className={styles.rateContainer}
                              id="rateContainer"
                              required
                            >
                              <i
                                className={`${styles.hide}`}
                                id="resetRatingStar"
                                val="0"
                              ></i>
                              <i
                                className={`${styles.ratingStar} fa-regular fa-star`}
                                val="1"
                              ></i>
                              <i
                                className={`${styles.ratingStar} fa-regular fa-star`}
                                val="2"
                              ></i>
                              <i
                                className={`${styles.ratingStar} fa-regular fa-star`}
                                val="3"
                              ></i>
                              <i
                                className={`${styles.ratingStar} fa-regular fa-star`}
                                val="4"
                              ></i>
                              <i
                                className={`${styles.ratingStar} fa-regular fa-star`}
                                val="5"
                              ></i>
                            </div>
                          </div>

                          <div className={styles.inputGrp}>
                            <label for="feedback">Feedback:</label>
                            <textarea
                              name="feedback"
                              id="feedback"
                              cols="20"
                              rows="10"
                              placeholder="Write your Feedback Here"
                            ></textarea>
                          </div>

                          <div className={styles.top}>
                            <div className={styles.inputGrp}>
                              <label for="file2">
                                Additional File (Optional):
                              </label>
                              <input
                                type="file"
                                name="file[]"
                                id="file2"
                                multiple="multiple"
                                accept="*"
                              />
                            </div>

                            <button type="submit" name="submit">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
          <Route path={`${path} /welcome`}>
            <div className={styles.preSets} id="preSetsParent">
              <div>
                <span
                  id="preSetsLoaderA"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                  }}
                >
                  Loading...&nbsp;
                  <LoaderPre />
                </span>
                <form
                  id="preSetsForm"
                  style={{ display: "none" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handlePreSetsForm();
                  }}
                >
                  <img src={somoLogo} alt="Somo" />
                  <span id="preSetsInfo"></span>
                  <p>Select your Hub location</p>
                  <label for="proposalUpload">
                    <select
                      id="locationValue"
                      name="location"
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <option value="" selected={true} disabled={true}>
                        Select Location
                      </option>
                      <option value="Nairobi">Nairobi</option>
                      <option value="Kisumu">Kisumu</option>
                      <option value="Mombasa">Mombasa</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Nakuru">Nakuru</option>
                    </select>
                  </label>
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePreSetsForm();
                    }}
                  >
                    Save & Continue
                    <span id="preSetsLoader" style={{ display: "none" }}>
                      <LoaderIntro />
                    </span>
                  </button>
                  <div
                    id="preSetsLoaderWait"
                    className={styles.preSetsWait}
                    style={{ display: "nones" }}
                  >
                    <LoaderIntroWait />
                    <p>
                      <b>Setting Up Your Somo Space...</b>
                      <br />
                      This may take a few minutes (2 to 5 mins)
                      <br />
                      Be patient and do not refresh the page.
                      <br />
                      Leave everything unto us.
                    </p>
                    <img src={somoLogo2} alt="Somo" />
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.creditTable} id="mainWelcomePageHolder">
              <div className={styles.welcomeAcc}>
                <h2>Welcome to Somo Buruka Program</h2>
                <img
                  src={welcomeToAcc}
                  alt="Welcome to Somo Buruka Program"
                  style={{ maxHeight: "250px" }}
                />
                <hr />
                <p>
                  Congratulations for making it this far. It's time to submit
                  your application.
                  <br />
                  Here's what you need to submit to complete your application
                  process.
                </p>
                <ol className={styles.steps} id="questionsOverview">
                  <LoaderIntro />
                  {/* <li>Question 1</li> */}
                </ol>
                <i>*</i>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  className="mt-4"
                  clicked={() =>
                    history.push("/dashboard/applicant/apply/start")
                  }
                >
                  Start Now <FaRocket />
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/---future---`}>
            <div className={styles.creditTable}>
              {/* <Table striped hover className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th>Loan ID</th>
                    <th>Monthly Repayment</th>
                    <th>Loan Fund</th>
                    <th>Status</th>
                    <th>Requested Amount</th>
                    <th>Balance</th>
                    <th>Approved Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.map((loanInstance, idx) => {
                    let loanBalance = loanInstance?.repayment
                      .filter((repaid) => {
                        return repaid.status === true;
                      })
                      .reduce((acc, curr) => {
                        return curr.scheduledAmount + acc;
                      }, 0);

                    loanBalance = loanInstance?.approvedAmount - loanBalance;

                    let overpayment = loanInstance?.repayment
                      .map((repayment) => repayment.penaltyFee)
                      .reduce((acc, curr) => {
                        return acc + curr;
                      }, 0);

                    return (
                      <tr key={idx}>
                        <td>{loanInstance._id.substring(0, 5)}</td>
                        <td>{`Ksh ${numberWithCommas(
                          (loanInstance.monthlyRepayment ? loanInstance.monthlyRepayment : '--')
                        )}`}</td>
                        <td>
                            {loanInstance.loanFundName}
                        </td>
                        <td>{_.startCase(loanInstance.status)}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.amount)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanBalance)}`}</td>
                        <td>{`Ksh ${numberWithCommas(loanInstance.approvedAmount)}`}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {(!loans || loans.length === 0) && (
                <div className={styles.noLoanMessage}>
                  <p>Sorry you currently have no loan</p>
                  <img src={noLoan} alt="No loan history" height="250" />
                  <TiCancelOutline
                    className={styles.mobileNoLoan}
                    size="6em"
                    color="rgba(116, 23, 99, 0.6)"
                  />
                </div>
              )} */}

              <div className={styles.noLoanMessage}>
                <p>Sorry you currently have no loan</p>
                <img src={noLoan} alt="No loan history" height="250" />
                <TiCancelOutline
                  className={styles.mobileNoLoan}
                  size="6em"
                  color="rgba(116, 23, 99, 0.6)"
                />
              </div>
            </div>
          </Route>
          <Route path={`${path}/apply`}>
            <main className={styles.applyContainer} id="mainApplyPageHolder">
              <Row>
                <Col md={4}>
                  <ul className={styles.joinedBullets} id="questionsListSteps">
                    <LoaderIntro />
                  </ul>
                </Col>
                <Col md={8}>
                  <div className={styles.applyForm}>
                    <Switch>
                      <Route path={`${path}/apply/:question`}>
                        <ApplicantUniUploadForm delegateApply={calculateLoan} />
                      </Route>
                    </Switch>
                  </div>
                </Col>
              </Row>
            </main>
          </Route>
          <Route path={`${path}/success`}>
            <div className={styles.applicationComplete}>
              <FaCheckCircle size="4em" color="#cc6328" />
              <h2>Onboarding Complete!</h2>
              <p>Your onboarding is complete.</p>
              <p>
                <a
                  href="/dashboard/overview"
                  style={{
                    padding: "7px",
                    display: "inline-block",
                    border: "2px solid #007bff",
                    margin: "14px auto",
                    borderRadius: "5px",
                  }}
                >
                  Go to my dashboard
                </a>
              </p>
            </div>
          </Route>
          <Route path={`${path}/too_late`}>
            <div className={styles.applicationComplete}>
              <FaBomb size="4em" color="#cc6328" />
              <h2>Too Late!</h2>
              <p style={{ textAlign: "left" }}>
                We're sorry to notify you that your Buruka Submission cannot
                proceed since the deadline has passed.
              </p>
              <p style={{ textAlign: "left" }}>For more information: </p>
              <ul style={{ textAlign: "left" }}>
                <li>reach out to your mentor or trainer </li>
                <li>
                  or send an email to{" "}
                  <a href="mailto:trainers@somoafrica.org">
                    Trainers@somoafrica.org
                  </a>{" "}
                </li>
                <li>
                  or visit your nearest{" "}
                  <a href="https://www.somoafrica.org/contacts" target="_">
                    Somo Hub.
                  </a>
                </li>
              </ul>
            </div>
          </Route>
          <Route path={`${path}/approvals`}>
            <div className={styles.applicationComplete}>
              <div
                style={{ position: "relative", width: "64px", margin: "auto" }}
              >
                <FaStopwatch size="4em" color="#cc6328" />
                <span
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: 0,
                    bottom: "1px",
                    transform: "scale(1.4)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    color: "#000000",
                    border: "2px dotted #cc6328",
                    borderRadius: "50%",
                  }}
                >
                  <LoaderPre color="#ffffff" />
                </span>
              </div>
              {/* <h2>Awaiting Judging!</h2> */}
              <h2>Submission Successful</h2>
              <p style={{ textAlign: "left" }}>
                Your submission was successful and advanced to the judging
                round.
                <br />
                For now, be calm and just wait.
                <br />
              </p>
              <p style={{ textAlign: "left" }}>
                Empowering you, to change the world{" "}
              </p>
              <ol style={{ textAlign: "left" }} id="stepsAwaitingApproval">
                {/* <li></li> */}
              </ol>
            </div>
          </Route>
          <Route path={`${path}`}>
            <div className={styles.applicationComplete}>
              <FaUserShield size="4em" color="#cc6328" />
              &nbsp;
              <FaBookReader size="4em" color="#cc6328" />
              <h2>Somo Advisory Playbook</h2>
              <p style={{ textAlign: "left" }}>
                {" "}
                No page Selected.
                <br /> Click either link below for a quick start.{" "}
              </p>
              <ul style={{ textAlign: "left" }} ids="stepsAwaitingApproval">
                <li>
                  <a href="/dashboard/playbook/home">Playbook Home</a>
                </li>
                <li>
                  <a href="/dashboard/playbook/advisors">Advisors</a>
                </li>
                <li>
                  <a href="/dashboard/playbook/playbooks">Playbooks</a>
                </li>
                <li>
                  <a href="/dashboard/playbook/alerts">Alerts</a>
                </li>
              </ul>
              {/* <iframe style={{ width: "100%", minHeight: "240px" }} src="https://www.youtube.com/embed/_VfCIEgXePI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <p style={{ textAlign: "left", fontStyle: "italic" }}>
                Empowering you, to change the world{" "}
              </p>
            </div>
          </Route>
        </Switch>
      </Dashboard>
      <Modal
        className={styles.continueModal}
        size="sm"
        show={incomplete}
        onHide={() => {
          clearCompleteState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Continue Application</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have an incomplete loan request, please continue where you left
            off
          </p>
          <Button
            clicked={resumeApplication}
            fullWidth
            className="mt-4"
            bgColor="#cc6328"
            size="sm"
            color="#EBEBEB"
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QSurvey;
